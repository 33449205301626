import React, { useState } from "react";
import { X } from "lucide-react";
import OtpInput from "./OtpInput";

function Login({ closeLoginModal }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [error, setError] = useState(false);

  const handleOnChange = (e) => {
    setPhoneNumber(e.target.value);
    // console.log(e.target.value);
    // handleError();
  };

  const handleOnClick = (e) => {
    setShowOtpInput(true);
  };

  const onOtpSubmit = (e) => {
    console.log("Login Successful");
    // closeLoginModal();
   
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // phone validations
    const regex = /[^0-9]/g;
    if (
      phoneNumber.length < 10 ||
      regex.test(phoneNumber) ||
      phoneNumber.length > 10
    ) {
      setError(true);
      return;
    }

    // Call BE API
    // show OTP Field
    setShowOtpInput(true);
  };

  return (
    <div>
      <div className="fixed z-[99999] inset-0  overflow-y-hidden  transition-opacity ">
        <div className="h-full justify-center sm:items-center backdrop-opacity-30 backdrop-invert bg-white/30 flex">
          <div className="bg-white shadow-xl mt-5 h-1/3 sm:h-1/3 w-full sm:w-1/3 rounded-md flex flex-col mx-2">
            <div className="flex justify-end">
              <button onClick={closeLoginModal} className="text-black p-2">
                <X size={20} />
              </button>
            </div>
            <div className="flex justify-center h-5 sm:mx-16">
              <p className="text-black font-medium font-sans text-lg">
                Login Or SignUp with Phone
              </p>
            </div>
            {!showOtpInput ? (
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="flex flex-col justify-between items-center font-normal mt-5">
                  <div className=" mx-5 gap-1 sm:w-2/3 flex">
                    <input
                      className="border-1 outline-none border-lime-700 rounded h-10 p-2 sm:w-full"
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => handleOnChange(e)}
                      placeholder="Type Phone Number"
                    />
                    <button
                      type="submit"
                      //   onClick={(e) => handleOnClick(e)}
                      className="bg-lime-700 rounded w-1/3 p-2 text-white"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {error ? (
                  <div className="text-red-600 flex justify-around">
                    Invalid Phone Number.
                  </div>
                ) : (
                  ""
                )}
              </form>
            ) : (
              <div>
                <div className="flex mt-10 justify-center h-5 sm:mx-16">
                  <p className="text-black font-medium text-base">
                    Enter OTP sent to {phoneNumber}
                  </p>
                </div>
                <OtpInput
                  length={4}
                  onOtpSubmit={onOtpSubmit}
                  closeLoginModal={closeLoginModal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
