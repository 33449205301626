import "./App.css";
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
// import Header from "./components/header/header";
import Footer from "./components/footer/footer";
// import Body from "./components/body/body";
import Header from "./components/Header/Header_lg";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./pages/Home/Home";
import data from "./data";
import { useEffect, useState } from "react";
import ProductDetails from "./pages/Product/ProductDetails";
import { useParams } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import ProductList from "./pages/ProductListCategoryWise/ProductList";
import Headerlg from "./components/Header/Header";
import AllCategories from "./pages/All_Categories/AllCategories";
import ViewCart from "./pages/Cart/ViewCart";
import { Router } from "lucide-react";

function App() {
  const [productPage, setProductPage] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const [category_name, setcategory_name] = useState("No Value");

  return (
    <div className=" sm:overflow-y-scroll mx-auto bg-white bg-opacity-100">
      <div
        className={
          productPage
            ? "sticky shadow-md z-[105] -mt-2 pb-0 top-0 bg-white border-gray-400 lg:hidden"
            : "hidden"
        }
      >
        <NavBar
          setProductPage={setProductPage}
          category_name={category_name}
          setcategory_name={setcategory_name}
        />
      </div>

      <div
        className={
          productPage
            ? "hidden"
            : "lg:hidden z-[105] sticky bg-gradient-to-b from-slate-100 to-white  shadow-md top-0 "
        }
      >
        <Headerlg />
      </div>

      <div
        className="hidden lg:block z-[102] sticky shadow-md top-0 bg-white border-b"
        // {
        //   productPage
        //     ? "hidden lg:block z-[105] -mt-3 sticky shadow-md top-0 bg-white border-b pb-0 border-gray-400"
        //     : "lg:block z-[105] sticky shadow-md -mt-3  top-0 bg-white border-b pb-0 border-gray-400"
        // }
      >
        <Header />
      </div>
      <div>
        {/* <div className="relative"> */}
        <main className="overflow-x-hidden sm:fixed sm:left-0 sm:top-0 sm:max-h-screen  sm:w-screen">
          <Routes>
            <Route
              path="/"
              exact={true}
              element={
                <Home
                  setProductPage={setProductPage}
                  prodData={data}
                  setcategory_name={setcategory_name}
                  setIsCartOpen={setIsCartOpen}
                />
              }
            />
            <Route
              path="/product/:category/:id/:name"
              exact={true}
              element={
                <ProductDetails setProductPage={setProductPage} data={data} />
              }
            />
            <Route
              path="/product/:category"
              exact={true}
              element={
                <ProductList data={data} setProductPage={setProductPage} />
              }
            />
            <Route
              path="/categories"
              exact={true}
              element={
                <AllCategories
                  data={data}
                  setProductPage={setProductPage}
                  setcategory_name={setcategory_name}
                />
              }
            />
            <Route path="/cart" exact={true} element={<ViewCart />} />
          </Routes>
        </main>
        {/* </div> */}
        <div className="pb-24"></div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
