import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

import "./Carousel.css";

function Carousel({ images }) {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === images.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? images.length - 1 : slide - 1);
  };

  // console.log(images);

  return (
    <div className="flex relative flex-col gap-2 justify-center items-center max-w-max max-h-max">
      <div className="flex relative gap-1 flex-row items-center">
        <button
          onClick={prevSlide}
          className=" py-1 text-base absolute inset-y-0 sm:left-0 -left-7 !px-0 false "
        >
          <div className="flex items-center justify-center">
            <div className="bg-white px-1 content-evenly mx-2 h-8 w-8 items-center justify-center rounded-full shadow-lg lg:block">
              <ChevronLeft className=" size-5" />
            </div>
          </div>
        </button>
        <div className="sliding overflow-x-scroll overflow-hidden flex">
          {images.map((image, idx) => {
            return (
              <img
                src={image}
                alt="Product images"
                key={idx}
                className={
                  slide === idx ? "slide rounded-md border size-auto" : "hidden"
                }
                // loading="lazy"
                
                decoding="async"
                // className="slide rounded-md shadow-sm size-auto"
              />
            );
          })}
        </div>
        <button
          onClick={nextSlide}
          className="absolute text-base inset-y-0 sm:right-0 -right-7 !px-0"
        >
          <div className="flex items-center justify-center">
            <div className="bg-white px-2 content-evenly  mx-2 h-8 w-8 items-center justify-center rounded-full shadow-lg lg:block">
              <ChevronRight className=" size-5" />
            </div>
          </div>
        </button>
      </div>
      {/* <div className="relative"> */}
      <span className="indicators gap-0 -mb-7 flex bottom-4">
        {images.map((_, idx) => {
          return (
            <button
              className={
                slide === idx ? "indicator" : "indicator indicator-inactive"
              }
              key={idx}
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span>
      {/* </div> */}
    </div>
  );
}

export default Carousel;
