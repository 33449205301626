import React from "react";
import { useNavigate } from "react-router-dom";

function ProductTemplate({ data, setProductPage }) {
  // console.log(data);
  const navigate = useNavigate();

  const handleOnclick = (id, name) => {
    setProductPage(true);
    navigate(
      `/product/${data.category
        .replace(/ /g, "")
        .replaceAll(",", "-")}/${id}/${name
        .replaceAll("-", "")
        .replaceAll(",", "")
        .replaceAll(" ", "-")
        .toLowerCase()}`
    );
  };

  return (
    // <div className="grid grid-cols-2 h-full w-full gap-x-3 gap-y-6 px-2.5 sm:grid-cols-3 md:grid-cols-4 xl:px-4 lg:grid-cols-5">
    // <div className="grid">
    <div className="bg-white drop-shadow-sm border flex flex-col relative  border-gray-300 w-auto sm:h-auto p-1  rounded-t-xl rounded-b-md">
      {/* Image */}
      <div
        href="/"
        onClick={() => handleOnclick(data.prod_id, data.prod_name)}
        className="flex items-center justify-center border-b border-gray-200"
      >
        <img src={data.prod_images[0]} className="size-auto relative my-2" />
      </div>

      {/* product Name and weight */}
      <div
        href="/"
        onClick={() => handleOnclick(data.prod_id, data.prod_name)}
        className="!h-12 lg:!h-16"
      >
        <span className="mt-1">
          <h5 className="capitalize  block overflow-hidden !text-base !font-semibold !h-12 !tracking-normal px-1">
            {data.prod_name}
          </h5>
        </span>
        <span>
          <h4 className="font-norms overflow-hidden block mt-1 !text-sm !font-normal px-1">
            {data.weight}
          </h4>
        </span>
      </div>

      {/* Price and add to cart */}
      <div className="relative mt-4 px-1.5">
        <div className="flex h-8 gap-1 sm:h-10 items-end">
          <span className="font-norms block overflow-hidden !text-sm md:!text-sm lg:!text-md !font-semibold !m-0">
            {data.selling_price}
          </span>
          <span className="text-sm line-through">{data.mrp}</span>
        </div>
        <button
          className=" py-1 px-7 items-center flex justify-center border-1 mt-2 !text-2xs sm:!text-base !w-full sm:!h-auto h-10
            text-lime-700 border-lime-700 !bg-lime-100 rounded-lg"
        >
          <div className="flex items-center justify-center">
            <span className="text-2xs sm:text-xs md:text-sm lg:text-md !font-bold">
              Add to Cart
            </span>
          </div>
        </button>
      </div>
    </div>

    // </div>

    // </div>
  );
}

export default ProductTemplate;
