import React, { useState, useEffect } from "react";
import Logo from "../../assets/icons-search.gif";
import LocationModal from "../Location/LocationModal";
import brandlogo from "../../assets/logo-transparent.png";
import { ChevronDown, ShoppingCart } from "lucide-react";
import usericon from "../../assets/user-icon.png";
import UtilLocation from "../Location/UtilLocation";
import Login from "../login_page/Login";
import LocationManual from "../Location/LocationManual";
import getLocationName from "../../hooks/getLocationName";

function Header() {
  const [cartItemCount, setCartItemCount] = useState(20);
  const fromLocMadal = false;
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState(null);
  const [localName, setLocalName] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  const closeLocModal = () => setIsOpen(false);
  const closeLoginModal = () => setIsLogin(false);
  const closeLocationManual = () => setLocationManual(false);
  const [locationManual, setLocationManual] = useState(false);

  useEffect(() => {
    if (location !== null) {
      getLocation(location[0], location[1]);
    }
  }, [location]);

  useEffect(() => {
    getLocationStatus();
  }, [setLocation]);

  const getLocationStatus = async () => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          UtilLocation({ setLocation, setIsOpen });
        } else if (result.state === "denied") {
          setIsOpen(true);
        } else if (result.state === "prompt") {
          setIsOpen(true);
        }
      });
  };

  const getLocation = async (lat, long) => {
    const result = await getLocationName(lat, long);
    setLocalName(result);
  };

  // async function getLocation(lat, long) {
  //   const promise = await fetch(
  //     `${process.env.REACT_APP_LOCATION_NAME}reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`
  //   );
  //   return await promise.json();
  // }

  const showCartItem = () => {
    if (cartItemCount !== 0) {
      return (
        <div className="h-auto w-auto inline-flex absolute  top-2 left-5 bg-red-600 leading-4 rounded-full">
          <span className="relative inline-flex h-4 w-4 items-center justify-center rounded-full bg-skin-primary-violet p-2.5 text-[10px] text-sm font-semibold text-white">
            {cartItemCount}
          </span>
        </div>
      );
    } else {
    }
  };

  const handleOnClick = () => {
    if (localName !== "") {
      console.log(localName);
      setLocationManual(true);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <header className="mx-4 z-[102] py-1 pb-2 border-white overflow-hidden grid grid-cols-1 gap-1 lg:grid-cols-12 justify-between items-center">
        {/* Logo & Location */}
        <div className="flex flex-shrink-0 lg:col-span-4 xl:col-span-3  ">
          <div className="flex items-center rounded-md ">
            <a
              href="/"
              className="no-underline grid my-2 sm:text-3xl text-2xl  font-black leading-4 uppercase "
            >
              <img src={brandlogo} className="max-w-32" />
            </a>
          </div>
          <div
            className="flex flex-col h-16 p-2"
            onClick={() => handleOnClick()}
          >
            <div className="font-bold text-sm ">Delivery in 30 minutes</div>
            <div className="flex items-center w-full justify-center cursor-pointer">
              {localName ? (
                <span className="text-xs font-semibold line-clamp-2">
                  {localName}
                </span>
              ) : (
                <span className="text-sm">Select Location</span>
              )}
              <ChevronDown className="mt-1 px-0.5 ml-0" />
            </div>
          </div>
        </div>
        {/* Search Bar start*/}
        <form className=" lg:px-2 lg:col-span-6 xl:col-span-7 hidden lg:ml-9 xl:ml-0 lg:flex h-12 rounded-md ">
          <div className="flex flex-row border focus-within:ring-1 focus-within:ring-lime-600 rounded-md w-full gap-2">
            <img src={Logo} className=" size-6 mt-2 ml-2 cursor-pointer" />
            <input
              className="w-full h-10 text-base rounded-md focus:outline-none hover:bg-white"
              placeholder="Search Items......"
            />
          </div>
        </form>

        {/* Login & Cart */}
        <div
          className="flex justify-center lg:col-span-2 h-12 sm:h-full gap-2"
          onClick={() => setIsLogin(true)}
        >
          <button className="flex items-center p-2 gap-1 w-auto h-12 sm:h-16 text-sm xl:text-lg hover:bg-gray-100">
            <img src={usericon} className="size-11" />
            Login
          </button>
          <a
            href="/cart"
            className="relative no-underline cursor-pointer flex h-12 sm:h-16 items-center text-white px-2 w-24  gap-1 bg-lime-700 rounded border hover:bg-lime-600"
          >
            {showCartItem()}
            <ShoppingCart className="size-9 stroke-1" />
            <div className=" text-white sm:text-sm xl:text-lg">Cart</div>
          </a>
        </div>

        <form className="xl:col-span-6 lg:hidden h-12 rounded-md ">
          <div className="flex flex-row border-1 focus-within:ring-1 focus-within:ring-lime-600 rounded-md w-full gap-2">
            <img src={Logo} className=" size-6 mt-2 ml-2 cursor-pointer" />
            <input
              className="w-full h-10 text-base rounded-md focus:outline-none hover:bg-white"
              type="search"
              placeholder="Search Items......"
            />
          </div>
        </form>
      </header>

      {isOpen && (
        <LocationModal
          closeLocModal={closeLocModal}
          setLocation={setLocation}
          fromLocMadal={fromLocMadal}
          closeLocationManual={closeLocationManual}
          locationManual={locationManual}
          setLocationManual={setLocationManual}
        />
      )}

      {isLogin && <Login closeLoginModal={closeLoginModal} />}

      {locationManual && (
        <LocationManual
          setLocation={setLocation}
          closeLocationManual={closeLocationManual}
          closeLocModal={closeLocModal}
          setLocalName={setLocalName}
          
        />
      )}
    </>
  );
}

export default Header;
