import React, { useState, useRef, useEffect } from "react";
import leaflet from "leaflet";
import useLocalStorage from "../../hooks/useLocalStorage";
import useGeolocation from "../../hooks/useGeoLocation";
import { X, ChevronLeft } from "lucide-react";
import markerIcon from "../../assets/location.png";
import "./MapLeaf.css";
import getLocationName from "../../hooks/getLocationName";

function MapLeaf({
  closeLocationManual,
  closeMapWindow,
  selectedLocation,
  setLocalName,
  closeLocModal,
}) {
  const markedRef = useRef(
    selectedLocation.city +
      "," +
      selectedLocation.admin_name +
      "," +
      selectedLocation.country
  );
  const mapRef = useRef();
  const [newLocation, setNewLocation] = useState([
    selectedLocation.lat,
    selectedLocation.lng,
  ]);
  const [markedLocation, setMarkedLocation] = useState(
    selectedLocation.lat,
    selectedLocation.lng
  );

  useEffect(() => {
    mapRef.current = leaflet
      .map("map")
      .setView([newLocation[0], newLocation[1]], 17);

    leaflet
      .tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 20,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      })
      .addTo(mapRef.current);

    var myIcon = new leaflet.icon({
      iconUrl: markerIcon,
      iconSize: [38, 65],
      iconAnchor: [2, 4],
    });

    let markerOptions = {
      clickable: true,
      //   draggable: true,
      autoPan: true,
      riseOnHover: true,
      icon: myIcon,
    };

    var markerPopUp = leaflet
      .popup({
        keepInView: true,
        closeButton: false,
        className: "mar_popup",
        closeOnClick: false,
        autoClose: false,
        autoPan: false,
      })
      .setContent(
        "<b>You are here</b><br/> Place the pin to your exact location."
      );

    var marker1 = leaflet
      .marker([newLocation[0], newLocation[1]], markerOptions)
      .addTo(mapRef.current)
      .bindPopup(markerPopUp)
      .openPopup();

    mapRef.current.addEventListener("click", (e) => {
      marker1.setLatLng(e.latlng);
      setNewLocation([e.latlng.lat, e.latlng.lng]);
      setMarkedLocation(e.latlng);
    });

    // on dragging of map
    mapRef.current.addEventListener("move", (e) => {
      // console.log(e);
    });

    mapRef.current.addEventListener("ontouchmove", (e) => {
      // console.log(e);
      alert(e);
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      var name = await getLocationName(newLocation[0], newLocation[1]);
      markedRef.current = name;
      console.log(name.length)
    }
    fetchData();
  }, [newLocation]);

  const handleOnclick = async () => {
    var name =
      selectedLocation.city +
      "," +
      selectedLocation.admin_name +
      "," +
      selectedLocation.country;
    name = await getLocationName(newLocation[0], newLocation[1]);
    alert("Your selected location: " + name);
    setLocalName(name);
    closeMapWindow();
    closeLocationManual();
    closeLocModal();
  };
  return (
    <div className="fixed z-[10001] inset-0  overflow-y-hidden transition-opacity ease-in">
      <div className="absolute flex-col items-center sm:static inset-0  w-full justify-center backdrop-opacity-0 backdrop-invert flex">
        <div
          //   style={{ width: "650px" }}
          className="static justify-between overflow-hidden  transition-all sm:drop-shadow-md w-screen mt-[0px] sm:w-[650px] sm:h-[45px] bg-white sm:mt-[50px] sm:rounded-t-3xl sm:mx-2"
        >
          {/* Header of map window */}
          <div className="flex ml-[5px] sm:rounded-t-xl shadow-md">
            <button
              onClick={() => {
                closeLocationManual();
                closeMapWindow();
              }}
              className="text-black ml-1 block sm:hidden "
            >
              <ChevronLeft size={24} />
            </button>

            <p className="text-black block mt-1 text-center w-[650px] font-medium font-sans">
              Location Information
            </p>
            <button
              onClick={() => {
                closeLocationManual();
                closeMapWindow();
              }}
              className="text-black hidden sm:block p-2"
            >
              <X size={20} />
            </button>
          </div>

          {/* Map of mapwindow */}
        </div>
        <div
          id="map"
          className="sm:w-[650px] sm:h-[550px] overflow-hidden w-[600px] h-[100%]"
          ref={mapRef}
        />
        <div
          onClick={handleOnclick}
          onChange={handleOnclick}
          className="flex absolute z-[100001] cursor-pointer justify-center bottom-[4rem] rounded-lg sm:rounded-t-none bg-rose-600 sm:bottom-0 sm:-mb-[25px] items-center sm:rounded-b-3xl h-[67px] sm:h-[75px] w-[calc(100%-30px)] sm:w-[650px]"
        >
          <button className="sm:h-[75px] flex flex-col gap-2 sm:gap-0 sm:justify-evenly items-center w-[calc(100%-30px)] sm:w-[650px]">
            <p className={`${markedRef.current.length <= 50 ? 'sm:text-base':'sm:text-sm'} h-[25px] flex justify-start m-0 text-sm  font-bold text-white  sm:text-nowrap overflow-hidden max-w-[600px] p-1`}>
              {markedRef.current}
            </p>
            <p className=" h-[8px] flex justify-center items-center text-base sm:text-lg font-bold text-white">
              Confirm and Continue
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MapLeaf;
