const Data1 =  [
  {
    "city": "Ranchi", 
    "lat": "23.3600", 
    "lng": "85.3300", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Ranchi", 
    "capital": "admin", 
    "population": "1073427", 
    "population_proper": "1456528"
  }, 
  {
    "city": "Delhi", 
    "lat": "28.6100", 
    "lng": "77.2300", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Delhi", 
    "capital": "admin", 
    "population": "32226000", 
    "population_proper": "16753235"
  }, 
  {
    "city": "Mumbai", 
    "lat": "19.0761", 
    "lng": "72.8775", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "admin", 
    "population": "24973000", 
    "population_proper": "12478447"
  }, 
  {
    "city": "Kolkata", 
    "lat": "22.5675", 
    "lng": "88.3700", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "admin", 
    "population": "18502000", 
    "population_proper": "4496694"
  }, 
  {
    "city": "Bangalore", 
    "lat": "12.9789", 
    "lng": "77.5917", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "admin", 
    "population": "15386000", 
    "population_proper": "8443675"
  }, 
  {
    "city": "Chennai", 
    "lat": "13.0825", 
    "lng": "80.2750", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "admin", 
    "population": "12395000", 
    "population_proper": "6727000"
  }, 
  {
    "city": "Hyderabad", 
    "lat": "17.3617", 
    "lng": "78.4747", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Telangana", 
    "capital": "admin", 
    "population": "10494000", 
    "population_proper": "6993262"
  }, 
  {
    "city": "Pune", 
    "lat": "18.5203", 
    "lng": "73.8567", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "8231000", 
    "population_proper": "3124458"
  }, 
  {
    "city": "Ahmedabad", 
    "lat": "23.0225", 
    "lng": "72.5714", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Gujarat", 
    "capital": "minor", 
    "population": "8009000", 
    "population_proper": "5570585"
  }, 
  {
    "city": "Sūrat", 
    "lat": "21.1702", 
    "lng": "72.8311", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Gujarat", 
    "capital": "", 
    "population": "6538000", 
    "population_proper": "4466826"
  }, 
  {
    "city": "Lucknow", 
    "lat": "26.8500", 
    "lng": "80.9500", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "admin", 
    "population": "3382000", 
    "population_proper": "3382000"
  }, 
  {
    "city": "Jaipur", 
    "lat": "26.9000", 
    "lng": "75.8000", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Rajasthan", 
    "capital": "admin", 
    "population": "3073350", 
    "population_proper": "3073350"
  }, 
  {
    "city": "Kanpur", 
    "lat": "26.4499", 
    "lng": "80.3319", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "2701324", 
    "population_proper": "2701324"
  }, 
  {
    "city": "Mirzapur", 
    "lat": "25.1460", 
    "lng": "82.5690", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "2496970", 
    "population_proper": "2496970"
  }, 
  {
    "city": "Nagpur", 
    "lat": "21.1497", 
    "lng": "79.0806", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "2405665", 
    "population_proper": "2405665"
  }, 
  {
    "city": "Ghaziabad", 
    "lat": "28.6700", 
    "lng": "77.4200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "2375820", 
    "population_proper": "2375820"
  }, 
  {
    "city": "Supaul", 
    "lat": "26.1260", 
    "lng": "86.6050", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2229076", 
    "population_proper": "2229076"
  }, 
  {
    "city": "Vadodara", 
    "lat": "22.3000", 
    "lng": "73.2000", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Gujarat", 
    "capital": "", 
    "population": "2065771", 
    "population_proper": "2065771"
  }, 
  {
    "city": "Rajkot", 
    "lat": "22.3000", 
    "lng": "70.7833", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Gujarat", 
    "capital": "", 
    "population": "2043000", 
    "population_proper": "2043000"
  }, 
  {
    "city": "Vishakhapatnam", 
    "lat": "17.7042", 
    "lng": "83.2978", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Andhra Pradesh", 
    "capital": "", 
    "population": "2035922", 
    "population_proper": "2035922"
  }, 
  {
    "city": "Indore", 
    "lat": "22.7167", 
    "lng": "75.8472", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Madhya Pradesh", 
    "capital": "", 
    "population": "1994397", 
    "population_proper": "1994397"
  }, 
  {
    "city": "Thane", 
    "lat": "19.1972", 
    "lng": "72.9722", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "1886941", 
    "population_proper": "1886941"
  }, 
  {
    "city": "Bhopal", 
    "lat": "23.2599", 
    "lng": "77.4126", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Madhya Pradesh", 
    "capital": "admin", 
    "population": "1798218", 
    "population_proper": "1798218"
  }, 
  {
    "city": "Pimpri-Chinchwad", 
    "lat": "18.6186", 
    "lng": "73.8037", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "1727692", 
    "population_proper": "1727692"
  }, 
  {
    "city": "Patna", 
    "lat": "25.5940", 
    "lng": "85.1376", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "admin", 
    "population": "1684222", 
    "population_proper": "1684222"
  }, 
  {
    "city": "Bilaspur", 
    "lat": "22.0900", 
    "lng": "82.1500", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Chhattisgarh", 
    "capital": "", 
    "population": "1625502", 
    "population_proper": "1625502"
  }, 
  {
    "city": "Ludhiana", 
    "lat": "30.9100", 
    "lng": "75.8500", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "1618879", 
    "population_proper": "1618879"
  }, 
  {
    "city": "Āgra", 
    "lat": "27.1800", 
    "lng": "78.0200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "1585704", 
    "population_proper": "1585704"
  }, 
  {
    "city": "Madurai", 
    "lat": "9.9252", 
    "lng": "78.1198", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "minor", 
    "population": "1561129", 
    "population_proper": "1561129"
  }, 
  {
    "city": "Jamshedpur", 
    "lat": "22.7925", 
    "lng": "86.1842", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Jharkhand", 
    "capital": "", 
    "population": "1558000", 
    "population_proper": "1558000"
  }, 
  {
    "city": "Prayagraj", 
    "lat": "25.4358", 
    "lng": "81.8464", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "1536218", 
    "population_proper": "1536218"
  }, 
  {
    "city": "Nasik", 
    "lat": "19.9975", 
    "lng": "73.7898", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "1486053", 
    "population_proper": "1486053"
  }, 
  {
    "city": "Faridabad", 
    "lat": "28.4211", 
    "lng": "77.3078", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Haryana", 
    "capital": "", 
    "population": "1414050", 
    "population_proper": "1414050"
  }, 
  {
    "city": "Meerut", 
    "lat": "28.9800", 
    "lng": "77.7100", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "1305429", 
    "population_proper": "1305429"
  }, 
  {
    "city": "Jabalpur", 
    "lat": "23.1667", 
    "lng": "79.9333", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Madhya Pradesh", 
    "capital": "", 
    "population": "1267564", 
    "population_proper": "1267564"
  }, 
  {
    "city": "Kalyan", 
    "lat": "19.2502", 
    "lng": "73.1602", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "1246381", 
    "population_proper": "1246381"
  }, 
  {
    "city": "Vasai-Virar", 
    "lat": "19.3607", 
    "lng": "72.7956", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "1221233", 
    "population_proper": "1221233"
  }, 
  {
    "city": "Najafgarh", 
    "lat": "28.6092", 
    "lng": "76.9798", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Delhi", 
    "capital": "", 
    "population": "1203180", 
    "population_proper": "1203180"
  }, 
  {
    "city": "Varanasi", 
    "lat": "25.3189", 
    "lng": "83.0128", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "1198491", 
    "population_proper": "1198491"
  }, 
  {
    "city": "Srinagar", 
    "lat": "34.0900", 
    "lng": "74.7900", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Jammu and Kashmir", 
    "capital": "admin", 
    "population": "1180570", 
    "population_proper": "1180570"
  }, 
  {
    "city": "Aurangabad", 
    "lat": "19.8800", 
    "lng": "75.3200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "1175116", 
    "population_proper": "1175116"
  }, 
  {
    "city": "Dhanbad", 
    "lat": "23.7998", 
    "lng": "86.4305", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Jharkhand", 
    "capital": "", 
    "population": "1162472", 
    "population_proper": "1162472"
  }, 
  {
    "city": "Amritsar", 
    "lat": "31.6400", 
    "lng": "74.8600", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Punjab", 
    "capital": "", 
    "population": "1132383", 
    "population_proper": "1132383"
  }, 
  {
    "city": "Aligarh", 
    "lat": "27.8800", 
    "lng": "78.0800", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "1131160", 
    "population_proper": "1131160"
  }, 
  {
    "city": "Guwahati", 
    "lat": "26.1722", 
    "lng": "91.7458", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Assam", 
    "capital": "", 
    "population": "1116267", 
    "population_proper": "1116267"
  }, 
  {
    "city": "Haora", 
    "lat": "22.5800", 
    "lng": "88.3294", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "1077075", 
    "population_proper": "1077075"
  }, 
  {
    "city": "Ranchi", 
    "lat": "23.3600", 
    "lng": "85.3300", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Jharkhand", 
    "capital": "admin", 
    "population": "1073440", 
    "population_proper": "1073440"
  }, 
  {
    "city": "Gwalior", 
    "lat": "26.2124", 
    "lng": "78.1772", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Madhya Pradesh", 
    "capital": "", 
    "population": "1069276", 
    "population_proper": "1069276"
  }, 
  {
    "city": "Chandigarh", 
    "lat": "30.7500", 
    "lng": "76.7800", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Chandigarh", 
    "capital": "admin", 
    "population": "1055450", 
    "population_proper": "1055450"
  }, 
  {
    "city": "Haldwani", 
    "lat": "29.2200", 
    "lng": "79.5200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttarakhand", 
    "capital": "", 
    "population": "1050000", 
    "population_proper": "1050000"
  }, 
  {
    "city": "Vijayavada", 
    "lat": "16.5193", 
    "lng": "80.6305", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Andhra Pradesh", 
    "capital": "", 
    "population": "1048240", 
    "population_proper": "1048240"
  }, 
  {
    "city": "Jodhpur", 
    "lat": "26.2800", 
    "lng": "73.0200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Rajasthan", 
    "capital": "", 
    "population": "1033918", 
    "population_proper": "1033918"
  }, 
  {
    "city": "Raipur", 
    "lat": "21.2444", 
    "lng": "81.6306", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Chhattisgarh", 
    "capital": "admin", 
    "population": "1010087", 
    "population_proper": "1010087"
  }, 
  {
    "city": "Kota", 
    "lat": "25.1800", 
    "lng": "75.8300", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Rajasthan", 
    "capital": "", 
    "population": "1001694", 
    "population_proper": "1001694"
  }, 
  {
    "city": "Bhayandar", 
    "lat": "19.2900", 
    "lng": "72.8500", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "809378", 
    "population_proper": "809378"
  }, 
  {
    "city": "Loni", 
    "lat": "28.7500", 
    "lng": "77.2800", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "516082", 
    "population_proper": "516082"
  }, 
  {
    "city": "Ambattūr", 
    "lat": "13.1143", 
    "lng": "80.1548", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "466205", 
    "population_proper": "466205"
  }, 
  {
    "city": "Salt Lake City", 
    "lat": "22.6100", 
    "lng": "88.4000", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "403316", 
    "population_proper": "403316"
  }, 
  {
    "city": "Bhatpara", 
    "lat": "22.8700", 
    "lng": "88.4100", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "386019", 
    "population_proper": "386019"
  }, 
  {
    "city": "Kūkatpalli", 
    "lat": "17.4849", 
    "lng": "78.4138", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Telangana", 
    "capital": "", 
    "population": "385821", 
    "population_proper": "385821"
  }, 
  {
    "city": "Dasarhalli", 
    "lat": "13.0465", 
    "lng": "77.5130", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "349720", 
    "population_proper": "349720"
  }, 
  {
    "city": "Muzaffarpur", 
    "lat": "26.1225", 
    "lng": "85.3906", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "333200", 
    "population_proper": "333200"
  }, 
  {
    "city": "Oulgaret", 
    "lat": "11.9570", 
    "lng": "79.7737", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "300104", 
    "population_proper": "300104"
  }, 
  {
    "city": "New Delhi", 
    "lat": "28.6139", 
    "lng": "77.2089", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Delhi", 
    "capital": "primary", 
    "population": "249998", 
    "population_proper": "249998"
  }, 
  {
    "city": "Tiruvottiyūr", 
    "lat": "13.1600", 
    "lng": "80.3000", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "249446", 
    "population_proper": "249446"
  }, 
  {
    "city": "Puducherry", 
    "lat": "11.9167", 
    "lng": "79.8167", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Puducherry", 
    "capital": "admin", 
    "population": "244377", 
    "population_proper": "244377"
  }, 
  {
    "city": "Byatarayanpur", 
    "lat": "13.0659", 
    "lng": "77.5922", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "239902", 
    "population_proper": "239902"
  }, 
  {
    "city": "Pallavaram", 
    "lat": "12.9675", 
    "lng": "80.1491", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "215417", 
    "population_proper": "215417"
  }, 
  {
    "city": "Secunderabad", 
    "lat": "17.4399", 
    "lng": "78.4983", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Telangana", 
    "capital": "", 
    "population": "213698", 
    "population_proper": "213698"
  }, 
  {
    "city": "Shimla", 
    "lat": "31.1033", 
    "lng": "77.1722", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Himachal Pradesh", 
    "capital": "admin", 
    "population": "206575", 
    "population_proper": "206575"
  }, 
  {
    "city": "Puri", 
    "lat": "19.8000", 
    "lng": "85.8167", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Odisha", 
    "capital": "", 
    "population": "201026", 
    "population_proper": "201026"
  }, 
  {
    "city": "Murtazabad", 
    "lat": "28.7111", 
    "lng": "77.2688", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "189117", 
    "population_proper": "189117"
  }, 
  {
    "city": "Shrirampur", 
    "lat": "22.7500", 
    "lng": "88.3400", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "181842", 
    "population_proper": "181842"
  }, 
  {
    "city": "Chandannagar", 
    "lat": "22.8700", 
    "lng": "88.3800", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "166867", 
    "population_proper": "166867"
  }, 
  {
    "city": "Sultanpur Mazra", 
    "lat": "28.6981", 
    "lng": "77.0689", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Delhi", 
    "capital": "", 
    "population": "163716", 
    "population_proper": "163716"
  }, 
  {
    "city": "Krishnanagar", 
    "lat": "23.4000", 
    "lng": "88.5000", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "153062", 
    "population_proper": "153062"
  }, 
  {
    "city": "Barakpur", 
    "lat": "22.7600", 
    "lng": "88.3700", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "152783", 
    "population_proper": "152783"
  }, 
  {
    "city": "Bhalswa Jahangirpur", 
    "lat": "28.7354", 
    "lng": "77.1638", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Delhi", 
    "capital": "", 
    "population": "151427", 
    "population_proper": "151427"
  }, 
  {
    "city": "Nangloi Jat", 
    "lat": "28.6833", 
    "lng": "77.0667", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Delhi", 
    "capital": "", 
    "population": "150371", 
    "population_proper": "150371"
  }, 
  {
    "city": "Balasore", 
    "lat": "21.5033", 
    "lng": "86.9250", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Odisha", 
    "capital": "", 
    "population": "144373", 
    "population_proper": "144373"
  }, 
  {
    "city": "Dalūpura", 
    "lat": "28.6004", 
    "lng": "77.3194", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Delhi", 
    "capital": "", 
    "population": "132628", 
    "population_proper": "132628"
  }, 
  {
    "city": "Yelahanka", 
    "lat": "13.1007", 
    "lng": "77.5963", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "119891", 
    "population_proper": "119891"
  }, 
  {
    "city": "Titagarh", 
    "lat": "22.7400", 
    "lng": "88.3700", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "116541", 
    "population_proper": "116541"
  }, 
  {
    "city": "Dam Dam", 
    "lat": "22.6200", 
    "lng": "88.4200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "114786", 
    "population_proper": "114786"
  }, 
  {
    "city": "Bansbaria", 
    "lat": "22.9700", 
    "lng": "88.4000", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "103920", 
    "population_proper": "103920"
  }, 
  {
    "city": "Madhavaram", 
    "lat": "13.1482", 
    "lng": "80.2314", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "100442", 
    "population_proper": "100442"
  }, 
  {
    "city": "Abbigeri", 
    "lat": "13.0767", 
    "lng": "77.5250", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "100000", 
    "population_proper": "100000"
  }, 
  {
    "city": "Baj Baj", 
    "lat": "22.4828", 
    "lng": "88.1818", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "76837", 
    "population_proper": "76837"
  }, 
  {
    "city": "Garhi", 
    "lat": "28.6317", 
    "lng": "77.3186", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "75544", 
    "population_proper": "75544"
  }, 
  {
    "city": "Mirpeta", 
    "lat": "17.3200", 
    "lng": "78.5200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Telangana", 
    "capital": "", 
    "population": "66982", 
    "population_proper": "66982"
  }, 
  {
    "city": "Nerkunram", 
    "lat": "13.0619", 
    "lng": "80.2094", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "52570", 
    "population_proper": "52570"
  }, 
  {
    "city": "Kendraparha", 
    "lat": "20.5000", 
    "lng": "86.4200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Odisha", 
    "capital": "", 
    "population": "41404", 
    "population_proper": "41404"
  }, 
  {
    "city": "Sijua", 
    "lat": "23.7762", 
    "lng": "86.3303", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Jharkhand", 
    "capital": "", 
    "population": "39372", 
    "population_proper": "39372"
  }, 
  {
    "city": "Manali", 
    "lat": "13.1667", 
    "lng": "80.2667", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "37748", 
    "population_proper": "37748"
  }, 
  {
    "city": "Kankuria", 
    "lat": "24.6523", 
    "lng": "87.9604", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "36925", 
    "population_proper": "36925"
  }, 
  {
    "city": "Chakapara", 
    "lat": "22.6300", 
    "lng": "88.3500", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "35282", 
    "population_proper": "35282"
  }, 
  {
    "city": "Pappakurichchi", 
    "lat": "10.8137", 
    "lng": "78.7481", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "26889", 
    "population_proper": "26889"
  }, 
  {
    "city": "Herohalli", 
    "lat": "12.9911", 
    "lng": "77.4873", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "23851", 
    "population_proper": "23851"
  }, 
  {
    "city": "Madipakkam", 
    "lat": "12.9623", 
    "lng": "80.1986", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "20523", 
    "population_proper": "20523"
  }, 
  {
    "city": "Sabalpur", 
    "lat": "25.6053", 
    "lng": "85.1835", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "18810", 
    "population_proper": "18810"
  }, 
  {
    "city": "Bauria", 
    "lat": "22.4521", 
    "lng": "88.1853", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "16764", 
    "population_proper": "16764"
  }, 
  {
    "city": "Salua", 
    "lat": "22.6100", 
    "lng": "88.2700", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "15171", 
    "population_proper": "15171"
  }, 
  {
    "city": "Chik Banavar", 
    "lat": "13.0846", 
    "lng": "77.5014", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "14409", 
    "population_proper": "14409"
  }, 
  {
    "city": "Jalhalli", 
    "lat": "13.0333", 
    "lng": "77.5500", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "13063", 
    "population_proper": "13063"
  }, 
  {
    "city": "Chinnasekkadu", 
    "lat": "13.1609", 
    "lng": "80.2573", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "12854", 
    "population_proper": "12854"
  }, 
  {
    "city": "Jethuli", 
    "lat": "25.5378", 
    "lng": "85.2841", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "11572", 
    "population_proper": "11572"
  }, 
  {
    "city": "Nagtala", 
    "lat": "22.4667", 
    "lng": "88.3833", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "10419", 
    "population_proper": "10419"
  }, 
  {
    "city": "Pakri", 
    "lat": "25.5876", 
    "lng": "85.1580", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "9768", 
    "population_proper": "9768"
  }, 
  {
    "city": "Hunasamaranhalli", 
    "lat": "13.1435", 
    "lng": "77.6200", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "9741", 
    "population_proper": "9741"
  }, 
  {
    "city": "Hesarghatta", 
    "lat": "13.1391", 
    "lng": "77.4783", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "9485", 
    "population_proper": "9485"
  }, 
  {
    "city": "Bommayapalaiyam", 
    "lat": "11.9922", 
    "lng": "79.8499", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "8796", 
    "population_proper": "8796"
  }, 
  {
    "city": "Gundūr", 
    "lat": "10.7339", 
    "lng": "78.7184", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "8587", 
    "population_proper": "8587"
  }, 
  {
    "city": "Punadih", 
    "lat": "25.5484", 
    "lng": "85.2649", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "8273", 
    "population_proper": "8273"
  }, 
  {
    "city": "Hariladih", 
    "lat": "23.7333", 
    "lng": "86.4000", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Jharkhand", 
    "capital": "", 
    "population": "7917", 
    "population_proper": "7917"
  }, 
  {
    "city": "Alawalpur", 
    "lat": "25.4958", 
    "lng": "85.2021", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "7827", 
    "population_proper": "7827"
  }, 
  {
    "city": "Madnaikanhalli", 
    "lat": "13.0626", 
    "lng": "77.4642", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "7805", 
    "population_proper": "7805"
  }, 
  {
    "city": "Bagalūr", 
    "lat": "13.1330", 
    "lng": "77.6660", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "7519", 
    "population_proper": "7519"
  }, 
  {
    "city": "Kadiganahalli", 
    "lat": "13.1687", 
    "lng": "77.6283", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "7225", 
    "population_proper": "7225"
  }, 
  {
    "city": "Khanpur Zabti", 
    "lat": "28.7103", 
    "lng": "77.2781", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Uttar Pradesh", 
    "capital": "", 
    "population": "6994", 
    "population_proper": "6994"
  }, 
  {
    "city": "Mahuli", 
    "lat": "25.5430", 
    "lng": "85.2268", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "6683", 
    "population_proper": "6683"
  }, 
  {
    "city": "Zeyadah Kot", 
    "lat": "22.4445", 
    "lng": "88.3345", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "6457", 
    "population_proper": "6457"
  }, 
  {
    "city": "Arshakunti", 
    "lat": "13.0785", 
    "lng": "77.4225", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "6262", 
    "population_proper": "6262"
  }, 
  {
    "city": "Mirchi", 
    "lat": "25.5554", 
    "lng": "85.2139", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "5710", 
    "population_proper": "5710"
  }, 
  {
    "city": "Sonudih", 
    "lat": "25.1155", 
    "lng": "87.0214", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "5658", 
    "population_proper": "5658"
  }, 
  {
    "city": "Bayandhalli", 
    "lat": "12.9779", 
    "lng": "77.5688", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "5118", 
    "population_proper": "5118"
  }, 
  {
    "city": "Sondekoppa", 
    "lat": "13.0000", 
    "lng": "77.3667", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "4866", 
    "population_proper": "4866"
  }, 
  {
    "city": "Babura", 
    "lat": "25.0851", 
    "lng": "87.1092", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "4755", 
    "population_proper": "4755"
  }, 
  {
    "city": "Madavar", 
    "lat": "13.0525", 
    "lng": "77.4732", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "4678", 
    "population_proper": "4678"
  }, 
  {
    "city": "Kadabgeri", 
    "lat": "12.9965", 
    "lng": "77.4331", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "4573", 
    "population_proper": "4573"
  }, 
  {
    "city": "Nanmangalam", 
    "lat": "12.9381", 
    "lng": "80.1753", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Tamil Nadu", 
    "capital": "", 
    "population": "4386", 
    "population_proper": "4386"
  }, 
  {
    "city": "Taliganja", 
    "lat": "22.5041", 
    "lng": "88.3598", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "4278", 
    "population_proper": "4278"
  }, 
  {
    "city": "Tarchha", 
    "lat": "25.1116", 
    "lng": "87.0964", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "4198", 
    "population_proper": "4198"
  }, 
  {
    "city": "Belgharia", 
    "lat": "22.6581", 
    "lng": "88.3852", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "4193", 
    "population_proper": "4193"
  }, 
  {
    "city": "Kammanhalli", 
    "lat": "13.0155", 
    "lng": "77.6381", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "4144", 
    "population_proper": "4144"
  }, 
  {
    "city": "Ambapuram", 
    "lat": "16.5990", 
    "lng": "80.8938", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Andhra Pradesh", 
    "capital": "", 
    "population": "4110", 
    "population_proper": "4110"
  }, 
  {
    "city": "Sonnappanhalli", 
    "lat": "13.1557", 
    "lng": "77.6179", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "3996", 
    "population_proper": "3996"
  }, 
  {
    "city": "Kedihati", 
    "lat": "22.6508", 
    "lng": "88.4608", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "3910", 
    "population_proper": "3910"
  }, 
  {
    "city": "Doddajivanhalli", 
    "lat": "13.0086", 
    "lng": "77.6143", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "3792", 
    "population_proper": "3792"
  }, 
  {
    "city": "Simli Murarpur", 
    "lat": "25.5792", 
    "lng": "85.2401", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "3739", 
    "population_proper": "3739"
  }, 
  {
    "city": "Sonawan", 
    "lat": "25.5445", 
    "lng": "85.2387", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "3682", 
    "population_proper": "3682"
  }, 
  {
    "city": "Devanandapur", 
    "lat": "22.9326", 
    "lng": "88.3729", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "3449", 
    "population_proper": "3449"
  }, 
  {
    "city": "Tribeni", 
    "lat": "22.9867", 
    "lng": "88.3987", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "3305", 
    "population_proper": "3305"
  }, 
  {
    "city": "Huttanhalli", 
    "lat": "13.1651", 
    "lng": "77.6512", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "3240", 
    "population_proper": "3240"
  }, 
  {
    "city": "Nathupur", 
    "lat": "25.5163", 
    "lng": "85.2544", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "3129", 
    "population_proper": "3129"
  }, 
  {
    "city": "Bali", 
    "lat": "25.4810", 
    "lng": "85.2227", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "3128", 
    "population_proper": "3128"
  }, 
  {
    "city": "Vajarhalli", 
    "lat": "13.1022", 
    "lng": "77.4111", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "3009", 
    "population_proper": "3009"
  }, 
  {
    "city": "Alija Kotla", 
    "lat": "17.2333", 
    "lng": "78.5500", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Telangana", 
    "capital": "", 
    "population": "3000", 
    "population_proper": "3000"
  }, 
  {
    "city": "Saino", 
    "lat": "25.1134", 
    "lng": "87.0108", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2987", 
    "population_proper": "2987"
  }, 
  {
    "city": "Shekhpura", 
    "lat": "25.5725", 
    "lng": "85.1428", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2950", 
    "population_proper": "2950"
  }, 
  {
    "city": "Cachohalli", 
    "lat": "13.0010", 
    "lng": "77.4717", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "2946", 
    "population_proper": "2946"
  }, 
  {
    "city": "Andheri", 
    "lat": "19.2104", 
    "lng": "73.0464", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Maharashtra", 
    "capital": "", 
    "population": "2937", 
    "population_proper": "2937"
  }, 
  {
    "city": "Narayanpur Kola", 
    "lat": "25.1293", 
    "lng": "87.0076", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2919", 
    "population_proper": "2919"
  }, 
  {
    "city": "Gyan Chak", 
    "lat": "25.5496", 
    "lng": "85.2423", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2855", 
    "population_proper": "2855"
  }, 
  {
    "city": "Kasgatpur", 
    "lat": "13.1101", 
    "lng": "77.5045", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "2852", 
    "population_proper": "2852"
  }, 
  {
    "city": "Kitanelli", 
    "lat": "13.0095", 
    "lng": "77.4191", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "2827", 
    "population_proper": "2827"
  }, 
  {
    "city": "Harchandi", 
    "lat": "25.1000", 
    "lng": "87.0442", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2827", 
    "population_proper": "2827"
  }, 
  {
    "city": "Santoshpur", 
    "lat": "22.4650", 
    "lng": "88.2193", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "West Bengal", 
    "capital": "", 
    "population": "2808", 
    "population_proper": "2808"
  }, 
  {
    "city": "Bendravadi", 
    "lat": "12.3636", 
    "lng": "76.9137", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "2684", 
    "population_proper": "2684"
  }, 
  {
    "city": "Kodagihalli", 
    "lat": "12.9771", 
    "lng": "77.4651", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "2585", 
    "population_proper": "2585"
  }, 
  {
    "city": "Harna Buzurg", 
    "lat": "25.0981", 
    "lng": "87.0148", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2579", 
    "population_proper": "2579"
  }, 
  {
    "city": "Mailanhalli", 
    "lat": "13.1863", 
    "lng": "77.6963", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "2543", 
    "population_proper": "2543"
  }, 
  {
    "city": "Sultanpur", 
    "lat": "25.5248", 
    "lng": "85.2507", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Bihar", 
    "capital": "", 
    "population": "2473", 
    "population_proper": "2473"
  }, 
  {
    "city": "Adakimaranhalli", 
    "lat": "13.0633", 
    "lng": "77.4417", 
    "country": "India", 
    "iso2": "IN", 
    "admin_name": "Karnataka", 
    "capital": "", 
    "population": "2453", 
    "population_proper": "2453"
  }
]

export default Data1;