import React, { useEffect, useState } from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

function AllCategories({ setProductPage, data, setcategory_name }) {
  console.log(data);
  const navigate = useNavigate();
  // const [data, setData] = useState(props.data);
  const [catArray, setCateArray] = useState([{ cat_name: "", cat_img: "" }]);

  const catArr = [];

  useEffect(() => {
    setProductPage(true);
    data.categories.length !== 0 &&
      data.categories.map((item) => {
        catArr.push({ cat_name: item.cate_name, cat_img: item.cate_img[0] });
      });
    const newList = catArr.map(({ cat_name }) => cat_name);
    const list2 = catArr.filter(
      ({ cat_name }, index) => !newList.includes(cat_name, index + 1)
    );
    setCateArray(list2);
  }, [data]);

  const handleOnClick = (cat_name) => {
    setcategory_name(cat_name);
    navigate(`/product/${cat_name}`);
  };

  return (
    <div className="relative">
      {/* <div className="hidden sticky flex-col items-center justify-center sm:mt-20 lg:mt-20 rounded-b-md shadow-md sm:m-auto overflow-y-hidden max-w-full  bg-lime-700  text-white lg:block">
        <div className="flex items-center justify-center">
          <span className="capitalize text-xl font-semibold">Categories</span>
        </div>
      </div> */}
    <div className="flex flex-col items-center justify-center sm:mt-20 lg:mt-24 sm:m-auto overflow-y-hidden max-w-7xl ">
      
      <div className="gap-x-2 gap-y-2 grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 items-center justify-start">
        {catArray.length !== 0 &&
          catArray.map((item, index) => {
            return (
              <div
                onClick={() => handleOnClick(item.cat_name)}
                key={index}
                className="items-center rounded-xl h-36 p-2 w-auto sm:h-auto bg-slate-100  justify-center flex flex-col font-semibold hover:bg-cyan-200"
              >
                <div>
                  <img
                    src={item.cat_img}
                    className=" rounded-xl size-20 sm:size-36 md:size-44"
                  />
                </div>
                <span className="text-center overflow-hidden text-md sm:text-base md:text-lg lg:text-lg font-semibold tracking-tighter capitalize">
                  {item.cat_name}
                </span>
              </div>
            );
          })}
      </div>
    </div>
    </div>
  );
}

export default AllCategories;
