import React, { useEffect, useState } from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
// import data from "../../data/data";

function Categories(props) {
  const navigate = useNavigate();
  const [data, setData] = useState(props.data);
  const [catArray, setCateArray] = useState([{ cat_name: "", cat_img: "" }]);

  const catArr = [];

  useEffect(() => {
    data.setProductPage(true);
    data.prodData.categories.length !== 0 &&
      data.prodData.categories.map((item) => {
        catArr.push({ cat_name: item.cate_name, cat_img: item.cate_img[0] });
      });
    const newList = catArr.map(({ cat_name }) => cat_name);
    const list2 = catArr.filter(
      ({ cat_name }, index) => !newList.includes(cat_name, index + 1)
    );
    //   console.log(list2);
    setCateArray(list2);
  }, [data]);

  const handleOnClick = (cat_name)=>{
    data.setcategory_name(cat_name);
    navigate(`/product/${cat_name}`)
  }

  const OnClickOnSeeAll = ()=>{
    navigate(`/categories`)
  }

  return (
    <div className="mb-5">
      <div className="flex flex-row -mb-2  justify-between">
        <span className="text-black lg:text-xl text-lg sm:text-xl tracking-wide font-bold">
          Shop by Category
        </span>
        <button
          className="text-base py-1 px-7 border-none p-0 m-1 font-semibold whitespace-nowrap"
          type="button"
          aria-label="See All"
        >
          <div className="flex justify-center text-red-500 -space-x-1" onClick={()=>OnClickOnSeeAll()}>
            <p>See All</p>
            <ChevronRight className="  mt-0" />
          </div>
        </button>
      </div>

      <div className="gap-x-2 gap-y-2 grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 items-center justify-start">
        {catArray.length !== 0 &&
          catArray.map((item, index) => {
            return (
              <div onClick={()=>handleOnClick(item.cat_name)} key={index} className="items-center rounded-xl h-36 p-2 w-auto sm:h-auto bg-slate-100  justify-center flex flex-col font-semibold hover:bg-cyan-200">
                <div>
                  <img src={item.cat_img} className=" rounded-xl size-20 sm:size-36 md:size-44" />
                </div>
                <span className="text-center overflow-hidden text-md sm:text-base md:text-lg lg:text-lg font-semibold tracking-tighter capitalize">{item.cat_name}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Categories;
