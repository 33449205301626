const data = {
  categories: [
    {
      cate_name: " fruits & vegetables",
      cate_img: [
        "https://cdn.grofers.com/app/images/category/cms_images/rc-upload-1702618300089-5",
        "https://cdn.grofers.com/app/images/category/cms_images/rc-upload-1702618300089-5",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [
            {
              prod_name: "Fresho Coriander Leaves",
              prod_id: "10000097",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/10000097_19-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000097-2_1-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000097-3_2-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000097-4_1-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000097-5_1-fresho-coriander-leaves.jpg",
              ],
              brand_name: "",
              mrp: "₹13.7",
              selling_price: "₹9(₹0.09 / g)",
              weight: "100 g ",
            },
            {
              prod_name: "Fresho Onion",
              prod_id: "10000150",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/10000150_19-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000150-2_3-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000150-3_4-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000150-4_1-fresho-onion.jpg",
              ],
              brand_name: "",
              mrp: "₹189",
              selling_price: "₹138(₹27.6 / kg)",
              weight: "5 kg",
            },

            {
              prod_id: "10000098",
              prod_name: "Fresho Coriander Leaves, 250 g ",
              mrp: "₹28.08",
              selling_price: "₹20.5(₹0.08 / g)",
              weight: " 250 g ",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/10000098_9-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000098-2_1-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000098-3_2-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000098-4_1-fresho-coriander-leaves.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000098-5_1-fresho-coriander-leaves.jpg",
              ],
            },
            {
              prod_id: "10000150",
              prod_name: "Fresho Onion, 5 kg ",
              mrp: "₹189.04",
              selling_price: "₹138(₹27.6 / kg)",
              weight: " 5 kg ",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/10000150_19-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000150-2_3-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000150-3_4-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000150-4_1-fresho-onion.jpg",
              ],
            },
            {
              prod_id: "10000148",
              prod_name: "Fresho Onion, 1 kg ",
              mrp: "₹41.1",
              selling_price: "₹29(₹29 / kg)",
              weight: " 1 kg ",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/10000148_32-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/10000148-2_2-fresho-onion.jpg",
              ],
            },
            {
              prod_id: "40075537",
              prod_name: "Fresho Onion, 2 kg ",
              mrp: "₹78.08",
              selling_price: "₹57(₹28.5 / kg)",
              weight: " 2 kg ",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/40075537_5-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40075537-2_2-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40075537-3_3-fresho-onion.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40075537-4_1-fresho-onion.jpg",
              ],
            },
            {
              prod_id: "20000979",
              prod_name: "Fresho Palak - Cleaned, without roots, 250 g ",

              mrp: "₹24.66",
              selling_price: "₹18(₹0.07 / g)",
              weight: " without roots",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/20000979_12-fresho-palak-cleaned-without-roots.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/20000979-2_1-fresho-palak-cleaned-without-roots.jpg",
              ],
            },
          ],
        },
      ],
    },
    {
      cate_name: " snacks & branded foods",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/1237_1670927167688.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "biscuits & cookies",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [
            {
              prod_name:
                "Britannia Marie Gold Biscuit - Crunchy, Light, Zero Trans Fat, Ready To Eat",
              prod_id: "40197801",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/40197801_8-britannia-marie-gold-biscuits.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40197801-2_8-britannia-marie-gold-biscuits.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40197801-3_8-britannia-marie-gold-biscuits.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40197801-4_8-britannia-marie-gold-biscuits.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40197801-5_8-britannia-marie-gold-biscuits.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40197801-6_7-britannia-marie-gold-biscuits.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40197801-7_6-britannia-marie-gold-biscuits.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40197801-8_1-britannia-marie-gold-biscuits.jpg",
              ],
              brand_name: "Britannia",
              mrp: "₹150",
              selling_price: "₹127.5(₹121.43 / kg)",
              weight: "1.05 kg",
            },
          ],
        },
      ],
    },
    {
      cate_name: " foodgrains, oil & masala",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/1557_1670927467171.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [
            {
              prod_name: "Aashirvaad Shudh Chakki Atta",
              prod_id: "40127506",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/40127506_8-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-2_9-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-3_9-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-4_9-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-5_9-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-6_8-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-7_3-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-8_3-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-9_3-aashirvaad-shudh-chakki-atta.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40127506-10_2-aashirvaad-shudh-chakki-atta.jpg",
              ],
              brand_name: "Aashirvaad",
              mrp: "₹256",
              selling_price: "₹221(₹44.2 / kg)",
              weight: "5 kg ",
            },
            {
              prod_name: "Fortune  Sun Lite Refined Sunflower Oil",
              prod_id: "274145",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/274145_14-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-2_8-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-3_7-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-4_7-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-5_7-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-6_6-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-7_4-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-8_2-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-9_2-fortune-sun-lite-sunflower-refined-oil.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/274145-10_2-fortune-sun-lite-sunflower-refined-oil.jpg",
              ],
              brand_name: "",
              mrp: "₹140",
              selling_price: "₹126(₹126 / l)",
              weight: " 1 L Pouch",
            },
            {
              prod_name:
                "Sri Sri Tattva Pure Cow Ghee -  For Better Digestion and Immunity",
              prod_id: "10000150",
              prod_images: [
                "https://www.bigbasket.com/media/uploads/p/l/40118697_8-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-3_8-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-2_8-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-4_3-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-5_3-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-6_3-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-7_3-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-8_3-sri-sri-tattva-cows-ghee.jpg",
                "https://www.bigbasket.com/media/uploads/p/l/40118697-9_3-sri-sri-tattva-cows-ghee.jpg",
              ],
              brand_name: "",
              mrp: "₹360",
              selling_price: "₹330(₹0.66 / ml)",
              weight: "500 ml",
            },
          ],
        },
      ],
    },
    {
      cate_name: " beauty & hygiene",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/163_1698986628342.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [],
        },
      ],
    },
    {
      cate_name: " baby care",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/7_1688712826553.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [],
        },
      ],
    },
    {
      cate_name: "bakery, cakes & dairy",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/888_1688712847171.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [],
        },
      ],
    },
    {
      cate_name: "beverages",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/332_1680269002502.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [],
        },
      ],
    },
    {
      cate_name: "eggs, meat & fish",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/4_1670925897047.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [],
        },
      ],
    },
    {
      cate_name: "cleaning & household",
      cate_img: [
        "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/category/cms_images/icon/18_1692167327246.png",
        "",
      ],
      cate_level: "L1",
      sub_categories: [
        {
          sub_cate_name: "herbs & seasonings",
          sub_cate_img: ["", ""],
          sub_cate_level: "L2",
          products: [],
        },
      ],
    },
  ],
};

export default data;
