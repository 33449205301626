import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductTemplate from "../../components/Templates/ProductTemplate";

function ProductList({ data, setProductPage }) {
  const navigate = useNavigate();
  const [prod_data, set_prod_data] = useState([]);
  const { category } = useParams();
  const [laoding, setLoading] = useState(false);

  const prodArray = [];
  useEffect(() => {
    setProductPage(true);
    data.categories.length !== 0 &&
      data.categories.map((cate_item) => {
        if (cate_item.cate_name === category) {
          cate_item.sub_categories.length !== 0 &&
            cate_item.sub_categories.map((sub_cate_item) => {
              sub_cate_item.products.length !== 0 &&
                sub_cate_item.products.map((product) => {
                  prodArray.push({
                    category: category,
                    prod_id: product.prod_id,
                    prod_name: product.prod_name,
                    prod_images: product.prod_images,
                    mrp: product.mrp,
                    selling_price: product.selling_price,
                    weight: product.weight,
                  });
                });
            });
        }
      });
    if (prodArray.length !== 0) {
      set_prod_data(prodArray);
    } else {
      setLoading(true);
    }
  }, [data]);

  if (prodArray.length === 0 && laoding === true) {
    return (
      <div className="sm:mt-20 max-w-7xl sm:m-auto overflow-y-hidden">
        <div className="flex flex-col items-center justify-center">
          <div className="flex text-nowrap  overflow-hidden   font-semibold gap-1">
            <h5 className="!text-base">Products yet to come in</h5>
            <h5 className="capitalize !text-base">{category}</h5>
          </div>
          <div>
            <span
              className="cursor-pointer text-lime-800 text-lg underline"
              onClick={() => navigate("/")}
            >
              Please explore other products!!!
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col overflow-x-hidden items-center justify-center sm:mt-20 max-w-7xl sm:m-auto overflow-y-hidden">
      <div className="hidden w-full bg-lime-700  text-white lg:block">
        <div className="flex items-center justify-center">
          <span className="capitalize text-xl font-semibold">{category}</span>
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-2 m-2">
          {prod_data.length !== 0 &&
            prod_data.map((item, index) => {
              return (
                <ProductTemplate
                  data={item}
                  setProductPage={setProductPage}
                  key={index}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ProductList;
