import React, { useState } from "react";
import LocData from "./Data1.js";
import { X, ChevronLeft, Search } from "lucide-react";
import { IoMdLocate } from "react-icons/io";
import { PiMapPinArea } from "react-icons/pi";
import UtilLocation from "./UtilLocation";
import useGeolocation from "../../hooks/useGeoLocation.jsx";
import MapLeaf from "./MapLeaf.js";

function LocationManual({ closeLocationManual, setLocation, closeLocModal, setLocalName }) {
  const [value, setValue] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [showMapWindow, setShowMapWindow] = useState(false);

  const closeMapWindow = () => setShowMapWindow(false);

  const handleChange = (e) => {
    setValue(e.target.value);
    // setValue({ ...value, [e.target.id]: e.target.value });
  };

  // for now using current location later will use selected lat long
  const currentLocation = useGeolocation();
  const onSearch = (searchItem) => {
    setSelectedLocation(searchItem);
    // our api to fetch search result
    setValue(searchItem.city);

    setShowMapWindow(true);
  };

  const getGeoLocation = (event) => {
    UtilLocation((setLocation = { setLocation }));
    closeLocationManual();
    closeLocModal();
  };

  return (
    <div>
      <div className="fixed z-[1001] inset-0  overflow-y-hidden transition-opacity ease-in">
        <div className="absolute sm:static inset-0 h-full w-full justify-center backdrop-opacity-30 backdrop-invert bg-white/5 flex">
          <div
            // style={{ width: "39rem" }}
            className="static justify-between overflow-hidden  transition-all sm:drop-shadow-md w-[600px] h-full sm:h-[450px] bg-white mt-[0px] sm:mt-[50px] sm:rounded-3xl sm:mx-2"
          >
            <div className="flex sm:rounded-t-xl h-12 shadow-md">
              <button
                onClick={closeLocationManual}
                className="text-black ml-1 sm:hidden "
              >
                <ChevronLeft size={24} />
              </button>

              <p className="text-black block text-center w-full my-2 font-medium font-sans">
                Search Location
              </p>
              <button
                onClick={closeLocationManual}
                className="text-black hidden sm:block p-2"
              >
                <X size={20} />
              </button>
            </div>
            <div>
              {/* Search bar */}
              <div className="relative  justify-center mt-3">
                <div className="flex h-10 justify-between items-center rounded-md bg-gray-100 mx-3 border-1 border-gray-300">
                  <Search className="stroke-1 stroke-gray-400 size-5 ml-1" />
                  <input
                    type="text"
                    value={value}
                    onChange={handleChange}
                    id="location"
                    placeholder="Type your location"
                    className="h-full ml-2 w-full outline-none rounded-md text-sm bg-gray-100"
                  />
                </div>

                <div className="absolute w-full ">
                  <div className="max-h-[27rem] overflow-auto overscroll-y-contain mt-0">
                    <div className=" bg-white px-4 w-full border-opacity-[0.15] !mb-0 rounded-md ">
                      {LocData.filter((item) => {
                        const searchTerm = value.toLowerCase();
                        const fullName = item.city.toLowerCase();

                        return (
                          searchTerm &&
                          (fullName.includes(searchTerm) ||
                            fullName.startsWith(searchTerm)) &&
                          fullName !== searchTerm
                        );
                      }).map((item, index) => (
                        <div
                          key={index}
                          className="w-full h-[70px]  cursor-pointer text-left flex flex-row justify-start items-center"
                        >
                          <div
                            onClick={() => onSearch(item)}
                            className="flex w-full items-center flex-row gap-3"
                          >
                            <PiMapPinArea className="size-5" />
                            <div className="flex border-b-[1px] w-full justify-center items-start flex-col pt-3">
                              <div
                                className="text-base font-bold"
                                key={item.city}
                                value={value}
                              >
                                {item.city}
                              </div>
                              <div className="text-sm flex gap-1 text-gray-500">
                                <p>{item.admin_name}</p>
                                <p>{item.country}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-2 mx-3 mb-o">
                <div className="flex items-start">
                  <IoMdLocate
                    style={{ color: "rgb(244 63 94)" }}
                    className="mr-3 mt-1 w-5 stroke-rose-500"
                  />
                  <div className="sm:leading-none">
                    <p className="text-rose-400 font-bold mb-1">
                      Current Location
                    </p>
                    <p className="text-gray-400 leading-4 text-sm">
                      Enable your current location for better services
                    </p>
                  </div>
                  <button
                    onClick={() => getGeoLocation()}
                    className="border-1 p-2 flex items-center justify-center text-rose-400 text-base font-semibold border-gray-300 ml-5 rounded w-20 h-10"
                  >
                    Enable
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {showMapWindow && (<MapWindow closeLocationManual={closeLocationManual} closeMapWindow={closeMapWindow} selectedLocation={selectedLocation}/>)} */}
      {showMapWindow && (
        <MapLeaf
          closeLocationManual={closeLocationManual}
          closeMapWindow={closeMapWindow}
          selectedLocation={selectedLocation}
          setLocalName={setLocalName}
          closeLocModal={closeLocModal}
        />
      )}
    </div>
  );
}

export default LocationManual;
