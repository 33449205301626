import React from "react";
import { X, MapPin } from "lucide-react";
import { useState } from "react";
import UtilLocation from "./UtilLocation";
import LocationManual from "./LocationManual";

function LocationModal({
  closeLocModal,
  setLocation,
  closeLocationManual,
  locationManual,
  setLocationManual,
}) {
  const getGeoLocation = (event) => {
    UtilLocation((setLocation = { setLocation }));
    closeLocModal();
  };

  return (
    <>
      <div className="fixed inset-0 z-[999] overflow-y-hidden bg-black/70 transition-opacity ease-in">
        <div className="absolute inset-0 flex justify-left">
          <div className="mt-4 ml-0 sm:ml-10 flex flex-col gap-1 text-black">
            <div className="bg-gray-50 rounded-md px=20 py=10 flex flex-col mx-2">
              <div className="flex-row gap-1 max-w-sm flex p-0">
                <MapPin className="size-16 sm:size-20 stroke-2 stroke-gray-50 fill-lime-700" />
                <p className="text-sm sm:text-base mt-2 text-center ">
                  Please provide your current location to see products at nearby
                  store
                </p>
              </div>
              <div className="flex flex-row h-14 mb-3 p-2 mr-5 ml-5 gap-4">
                <button
                  type="button"
                  onClick={() => getGeoLocation(true)}
                  className="w-full  text-sm items-center rounded-md bg-lime-700 text-white"
                >
                  Use current location
                </button>
                <button
                  onClick={() => setLocationManual(true)}
                  className="w-full text-sm rounded-md border-1 border-lime-600"
                >
                  Search Manually
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {locationManual ? (
        <LocationManual
          closeLocationManual={closeLocationManual}
          setLocation={setLocation}
          closeLocModal={closeLocModal}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default LocationModal;
