import { createContext, useState, useEffect } from "react";
import { X, Minus, Plus, ChevronLeft } from "lucide-react";
import ReactDOM from "react-dom";
import { useHistory, useNavigate } from "react-router-dom";
import "./ViewCart.css";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {};

function ViewCart() {
  // let history = useHistory();

  const navigate = useNavigate();

  const handleItemCount = (e) => {
    e.preventDefault();
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0  z-[1001] bg-black/70">
      <div className="h-full py-1 absolute right-0 backdrop-opacity-30 backdrop-invert bg-white">
        <header className="sticky top-0 z-[105] mx-auto flex w-full items-center justify-between bg-skin-base p-2 shadow-md lg:flex">
          <div className="flex">
            <button
              onClick={() => navigate(-1)}
              className="text-base cursor-pointer !p-0 false "
            >
              <div className="flex items-center justify-center">
                <span className="mr-2">
                  <ChevronLeft />
                </span>
              </div>
            </button>
            <span className="leading-5 font-medium text-base overflow-hidden text-ellipsis">
              Cart (1)
            </span>
          </div>
          <a href="/search" className="no-underline">
            <span className=" bg-violet-900 w-20 justify-center flex font-semibold rounded-md m-1 text-xs p-2 text-white">
              Add More
            </span>
          </a>
          {/* <div className="hidden sm:block">
            <button>
              <X />
            </button>
          </div> */}
        </header>
        <div className="cart_body h-full bg-slate-200 relative">
          <div className="mx-auto py-2 lg:max-w-5xl">
            {/* <div className="sticky inset-x-0 top-12 z-[102]"> 
              <div className="flex items-center justify-center rounded-b-md py-1 bg-lime-400 shadow-md"></div>
            </div> */}
            <div className=" bg-white mb-2 px-4 pt-2.5">
              <div className="flex h-auto w-full items-start justify-between py-2 md:h-20">
                <img
                  src="https://www.bigbasket.com/media/uploads/p/l/10000097-2_1-fresho-coriander-leaves.jpg"
                  className="object-contain w-16 border rounded"
                />
                <div className="h-auto w-3/5 pl-4 pr-2">
                  <span className=" overflow-hidden">
                    Fresho Coriander Leaves
                  </span>
                  <div>
                    <p className="block font-normal mr-2 text-gray-500 md:!text-xs">
                      250 g
                    </p>
                  </div>
                </div>

                <div className="ml-2 flex min-w-[8rem] items-center justify-between">
                  <div>
                    <div className="flex border leading-6 w-auto font-semibold border-red-500 justify-between relative items-center mt-2 rounded-md gap-2 text-sm p-1 text-white m-1 bg-red-500">
                      <button>
                        <Minus className="size-4" />
                      </button>
                      1
                      <button>
                        <Plus className="size-4" />
                      </button>
                    </div>
                  </div>
                  <div className="pl-2 text-right">
                    <span>₹20.5</span>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}

export default ViewCart;
