import React, { useEffect, useState } from "react";
import Product from "./productList/product";
import Categories from "./Categories";
import ProductList from "./prod_list/product_list";
import Products from "./prod_list/prods";
import logofevicon from "../../assets/mylife-favicon-color.png";
import cate from "../../assets/category.png";
import cart from "../../assets/cart.png";

function Home(props) {
  const [cartItemCount, setCratItemCount] = useState(20);
  // console.log(props)
  const [data, setData] = useState(props);

  useEffect(() => {
    data.setProductPage(false);
  }, []);

  const onClickOnCart = () => {};

  const showCartItem = () => {
    if (cartItemCount !== 0) {
      return (
        <div className="h-auto w-auto inline-flex absolute  -top-2 left-4 bg-red-500 leading-4 rounded-full">
          <span className="relative inline-flex h-4 w-4 items-center justify-center rounded-full bg-skin-primary-violet p-2.5 text-[10px] text-sm font-semibold text-white">
            {cartItemCount}
          </span>
        </div>
      );
    } else {
    }
  };

  return (
    <div className="xl:flex xl:justify-center">
      <div className="sm:mt-32 lg:mt-24 max-w-7xl">
        <div className="m-2  mb-5">
          <Categories data={data} />
        </div>
        <div>
          <Products
            setProductPage={data.setProductPage}
            products={data.prodData}
          />
        </div>
      </div>
      <div className="fixed -bottom-2 z-[1000] flex flex-row w-full justify-around border-t bg-white px-1.5 pb-3.5 shadow-[rgba(0,0,10,0.05)_0px_-5px_4px_0px] lg:hidden">
        <a className="cursor-pointer no-underline text-black" href="/">
          <div className="!relative mt-3.5 hover:text-lime-500 flex flex-col items-center justify-center">
            <img src={logofevicon} className="max-h-9 sm:max-h-12" />
          </div>
        </a>
        <a
          aria-label="Category page"
          className="cursor-pointer no-underline text-black"
          href="/categories"
        >
          <div className="!relative hover:text-lime-500 mt-3.5 flex flex-col items-center justify-center">
            <img src={cate} className="size-8 sm:size-11" />
            {/* <LayoutGrid className="size-8 sm:size-11  fill-rose-800 stroke-rose-800 stroke-2 " /> */}
            {/* <p className="block font-bold mt-1 !text-sm text-rose-800 ">Categories</p> */}
          </div>
        </a>
        <a
          aria-label="Cart page"
          className="no-underline cursor-pointer text-black"
          href="/cart"
        >
          <div
            className={
              cartItemCount
                ? "!relative mt-3.5 flex flex-col items-center justify-center"
                : "!relative mt-2  flex flex-col items-center justify-center"
            }
          >
            {cartItemCount ? showCartItem() : ""}
            <img
              src={cart}
              className={
                cartItemCount ? "size-9 sm:size-14" : "size-11 sm:size-16"
              }
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default Home;
