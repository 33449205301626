import React, { useState, useEffect } from "react";
import Logo from "../../assets/icons-search.gif";
import LocationModal from "../Location/LocationModal";
import brnadLogo from "../../assets/logo-transparent.png";
import { ChevronDown } from "lucide-react";
import usericon from "../../assets/user-icon.png";
import UtilLocation from "../Location/UtilLocation";
import Login from "../login_page/Login";
import LocationManual from "../Location/LocationManual";
import getLocationName from '../../hooks/getLocationName';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState(null);
  const [localName, setLocalName] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  const closeLocModal = () => setIsOpen(false);
  const closeLoginModal = () => setIsLogin(false);
  const closeLocationManual = () => setLocationManual(false);
  const [locationManual, setLocationManual] = useState(false);

  useEffect(() => {
    if (location !== null) {
      getLocation(location[0], location[1]);
    }
  }, [location]);

  useEffect(() => {
    if (navigator.geolocation) {
      getLocationStatus();
    }
  }, []);

  const getLocationStatus = async () => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          UtilLocation({ setLocation, setIsOpen });
        } else if (result.state === "denied") {
          setIsOpen(true);
        } else if (result.state === "prompt") {
          setIsOpen(true);
        }
      });
  };

  const getLocation = async (lat, long) => {
    const result = await getLocationName(lat, long);
    setLocalName(result);
  };

  // async function getLocation(lat, long) {
  //   const promise = await fetch(
  //     `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`
  //   );
  //   return await promise.json();
  // }

  const handleOnClick = () => {
    if (localName !== "") {
      console.log(localName);
      setLocationManual(true);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div>
      <header className="mx-2 pb-2 py-1 border-white overflow-hidden grid grid-cols-1 sm:gap-2 justify-between items-center">
        {/* Logo & Location */}
        <div className="flex flex-shrink-0 flex-row col-span-3 h-12 mb-2">
          {/* Logo section start */}
          <div className="flex items-center rounded-md ">
            <a
              href="/"
              className="no-underline grid my-2 sm:text-3xl text-2xl  font-black leading-4 uppercase "
            >
              <img src={brnadLogo} className="max-w-28" />
            </a>
          </div>

          {/* Location section start */}
          <div
            className="flex flex-col flex-shrink-0 -mt-1 p-2 hover:bg-lime-100"
            onClick={() => handleOnClick()}
          >
            <div className="font-bold -mb-1 text-sm sm:text-base  flex gap-1">
              <span>Delivery in</span>
              <span>30 minutes</span>
            </div>
            <div className="flex items-center text-xs w-full justify-center">
              {localName ? (
                <span className="font-semibold truncate max-w-40">
                  {localName}
                </span>
              ) : (
                <span className="">Select Location</span>
              )}
              <ChevronDown className="mt-1 px-0.5 ml-0" />
            </div>
          </div>
          {/* Login section */}
          <div
            className="flex flex-end col-span-3 justify-end items-center w-full"
            onClick={() => setIsLogin(true)}
          >
            <img src={usericon} className="size-10" />
          </div>
        </div>

        {/* Search Bar start*/}
        <form className="flex flex-1 h-10 rounded-md ">
          <div className="flex w-full flex-row border focus-within:ring-1 focus-within:ring-lime-600 rounded-md gap-2">
            <img src={Logo} className=" size-6 mt-2 ml-2 cursor-pointer" />
            <input
              className="w-full h-8 text-base rounded-md focus:outline-none hover:bg-white"
              type="search"
              placeholder="Search Items......"
            />
          </div>
        </form>
      </header>
      {isOpen && (
        <LocationModal
          closeLocModal={closeLocModal}
          setLocation={setLocation}
          closeLocationManual={closeLocationManual}
          locationManual={locationManual}
          setLocationManual={setLocationManual}
        />
      )}

      {isLogin && <Login closeLoginModal={closeLoginModal} />}

      {locationManual && (
        <LocationManual
          setLocation={setLocation}
          closeLocationManual={closeLocationManual}
          closeLocModal={closeLocModal}
          setLocalName={setLocalName}
        />
      )}
    </div>
  );
}

export default Header;
