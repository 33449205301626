import React, { useState, useEffect } from "react";
// import { useNavigate  } from "react-router-dom";
import { Search, ChevronDown, ChevronLeft } from "lucide-react";
import LocationModal from "../Location/LocationModal";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LocationManual from "../Location/LocationManual";
import UtilLocation from "../Location/UtilLocation";

function NavBar({ setProductPage, category_name, setcategory_name }) {
  const page_location = useLocation();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState(null);
  const [localName, setLocalName] = useState(null);

  const closeLocModal = () => setIsOpen(false);
  const closeLocationManual = () => setLocationManual(false);
  const [locationManual, setLocationManual] = useState(false);

  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (location !== null) {
      getLocationName(location[0], location[1]);
    }
  }, [location]);

  useEffect(() => {
    if (navigator.geolocation) {
      getLocationStatus();
    }
  }, []);

  const getLocationStatus = async () => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          UtilLocation({ setLocation, setIsOpen });
        } else if (result.state === "denied") {
          setIsOpen(true);
        } else if (result.state === "prompt") {
          setIsOpen(true);
        }
      });
  };

  const getLocationName = async (lat, long) => {
    const result = await getLocation(lat, long);
    setLocalName(result.display_name);
  };

  async function getLocation(lat, long) {
    const promise = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`
    );
    return await promise.json();
  }

  function onLinkClick(e) {
    e.preventDefault();
    setProductPage(false);
    setcategory_name("No Value");
    // console.log(props.data);
    navigate("/");
    // further processing happens here
  }
  // const navigate = useNavigate();

  useEffect(() => {
    // setcategory_name(decodeURI(page_location.pathname).split('/')[2]);
    // console.log(decodeURI(page_location.pathname).split('/')[2])
    // console.log(page_location.pathname.split('/')[2]);
    // console.log(category_name);
    // console.log(page_location);
    window.addEventListener("beforeunload", alertUser);
    // return () => {
    //   window.removeEventListener("beforeunload", alertUser);
    // };
  }, []);
  const alertUser = (e) => {
    // e.preventDefault();
    // setcategory_name(decodeURI(page_location.pathname).split("/")[2]);
    e.returnValue = "";
  };

  const handleLocation = () => {
    if (category_name === "No Value") {
      return (
        <div className="relative flex h-[2.75rem] flex-1 flex-col justify-center lg:flex-initial">
          <span className="flex items-center gap-x-1 text-xl font-bold">
            <span className="text-black">Delivery in</span>
            <span className="font-extrabold text-red-600">16 Minutes​</span>
          </span>
          <button
            onClick={() => handleOnClick()}
            aria-haspopup="dialog"
            aria-label="Select Location"
            className="flex items-center gap-x-2 text-sm font-semibold"
            type="button"
          >
            <span className="max-w-[250px] gap-x-2 truncate text-black">
              <span className="">
                {localName ? localName : "Select Location"}
              </span>
            </span>
            <ChevronDown />
          </button>
        </div>
      );
    } else {
      return (
        <div className="capitalize text-lg sm:text-2xl font-semibold">
          {category_name}
        </div>
      );
    }
  };

  // Location related

  const handleOnClick = () => {
    if (localName !== "") {
      console.log(localName);
      setLocationManual(true);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div>
      <nav className="top-0 z-[105] mx-auto flex w-full items-center justify-between m-2 p-1 lg:hidden">
        <button
          className=" py-1 px-7 text-base !p-0 "
          type="button"
          onClick={() => navigate(-1)}
        >
          <div className="flex items-center justify-center">
            <span className="mr-2">
              <ChevronLeft className=" stroke-1 size-8 sm:size-9" />
            </span>
          </div>
        </button>
        {handleLocation()}
        <a href="/search" className="text-black">
          <button
            className="py-1 px-7 text-base !p-0 "
            type="button"
            aria-label="Search Icon"
          >
            <div className="flex items-center justify-center">
              <span className="mr-2">
                <Search className="size-6 stroke-1 sm:size-7" />
              </span>
            </div>
          </button>
        </a>
      </nav>

      {isOpen && (
        <LocationModal
          closeLocModal={closeLocModal}
          setLocation={setLocation}
          closeLocationManual={closeLocationManual}
          locationManual={locationManual}
          setLocationManual={setLocationManual}
        />
      )}

      {locationManual && (
        <LocationManual
          setLocation={setLocation}
          closeLocationManual={closeLocationManual}
          closeLocModal={closeLocModal}
        />
      )}
    </div>
  );
}

export default NavBar;
