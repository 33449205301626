export default function UtilLocation(props) {

    navigator.geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
        maximumAge: 1500,
      });

  function success(pos) {
    props.setLocation([pos.coords.latitude, pos.coords.longitude]);
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    if(err.code === 1){
        alert(`Turn on Location Services to Allow "MyLife Mart" to Determine Your Location by Clicking the Location icon in the Address bar, and then Always allow.`);
    }
  }
}
