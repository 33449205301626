import React from "react";

function Footer() {
  return (
    <div className="bg-black">
      <div>
        {/* <h1 className="text-slate-500">Footer</h1> */}
      </div>
      
    </div>
  );
}

export default Footer;
