import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import data from "../../../cat_prods";
import ProductTemplate from "../../../components/Templates/ProductTemplate";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./prods.css";
import { ChevronRight, ChevronLeft } from "lucide-react";

function Prods({ setProductPage, products }) {
  const [scrollLeftCount, setScrollLeftCount] = useState(0);
  const [scrollRightCount, setScrollRightCount] = useState(0);

  const scrolls = useRef();

  useEffect(() => {
    // if (scrolls.current.scrollLeft >= maxscrollValue) {
    //   console.log(scrollLeftCount);
    //   console.log(maxscrollValue);
    //   // setScrollRightCount(maxscrollValue);
    // }
  }, []);
  let maxscrollValue = 0;
  const [slide, setSlide] = useState(0);

  const scroll = (scrollOffset) => {
    scrolls.current.scrollLeft += scrollOffset;
    setScrollLeftCount(scrolls.current.scrollLeft);

    maxscrollValue =
      scrolls.current.scrollWidth - scrolls.current.clientWidth - 200;
    setScrollRightCount(maxscrollValue);

    // console.log(scrolls.current.scrollWidth);
    // console.log(scrolls.current.clientWidth);
    // console.log(maxscrollValue);
    // if (scrolls.current.scrollLeft >= maxscrollValue) {
    //   console.log("left Count is greater than max")
    //   console.log("left: ",scrollLeftCount);
    //   console.log("max: ",maxscrollValue);
    //   // setScrollRightCount(maxscrollValue);
    // }
  };

  // const nextSlide = () => {

  //   setSlide(slide === prod_data.length - 1 ? 0 : slide + 1);
  // };

  // const prevSlide = () => {
  //   setSlide(slide === 0 ? prod_data.length - 1 : slide - 1);
  // };

  const [prod_data, setprod_data] = useState([]);

  const prodCateArray = [{ cate: "", prodArray: [] }];
  const prodArray = [];

  useEffect(() => {
    products.categories.length !== 0 &&
      products.categories.map((cate) => {
        cate.sub_categories.length !== 0 &&
          cate.sub_categories.map((sub_item) => {
            sub_item.products.length !== 0 &&
              sub_item.products.map((product) => {
                // console.log(product);
                prodArray.push({
                  category: cate.cate_name,
                  prod_id: product.prod_id,
                  prod_name: product.prod_name,
                  prod_images: product.prod_images,
                  mrp: product.mrp,
                  selling_price: product.selling_price,
                  weight: product.weight,
                });
              });
          });
      });
    setprod_data(prodArray);
  }, [products]);

  return (
    <div className=" relative overflow-hidden prodlist w-auto">
      <div className="-mb-2">
        <span className="mx-2 font-bold text-lg">Category Name</span>
      </div>
      <div
        ref={scrolls}
        className="no-scrollbar -mx-4 overflow-x-scroll scroll-smooth px-4 sm:mx-0 sm:px-0"
      >
        {/* <Slider {...settings}> */}
        <div className="my-4 grid grid-cols-[repeat(12,_12rem)] grid-rows-1 gap-2 sm:grid-cols-[repeat(12,_10rem)] sm:gap-4">
          {prod_data.length !== 0 &&
            prod_data.map((data, index) => {
              // console.log(idx);
              return (
                <div key={index}>
                  <ProductTemplate
                    key={index}
                    data={data}
                    setProductPage={setProductPage}
                  />
                </div>
              );
            })}
        </div>

        {/* </Slider> */}
      </div>
      <div className="-mx-4">
        <button
          onClick={() => scroll(-200)}
          className={
            scrollLeftCount >= 200
              ? "absolute inset-y-0 left-0 z-[101] xl:left-0"
              : "hidden"
          }
        >
          <div className="flex h-8 w-8 items-center bg-white justify-center rounded-full bg-skin-base p-2 shadow-custom">
            <ChevronLeft />
          </div>
        </button>
        <button
          onClick={() => scroll(200)}
          className={
            scrollLeftCount > scrollRightCount
              ? "hidden"
              : "absolute inset-y-0 right-0 z-[101] xl:-right-2"
          }
        >
          <div className="flex h-8 w-8 items-center bg-white justify-center rounded-full bg-skin-base p-2 shadow-custom">
            <ChevronRight />
          </div>
        </button>
      </div>

      {/* <div className="grid grid-cols-2 h-full w-full gap-x-3 gap-y-6 px-2.5 sm:grid-cols-3 md:grid-cols-4 xl:px-4 lg:grid-cols-5">
        {prod_data.length !== 0 &&
          prod_data.map((data, idx) => {
            return (
              <ProductTemplate
                key={idx}
                data={data}
                setProductPage={setProductPage}
              />
            );
          })}
      </div> */}
    </div>
  );
}

export default Prods;

{
  /* {data.products.length !== 0 &&
        data.products.map((item, index) => {
          return (
            <div
              className="bg-white border-1 hover:border-lime-600 border-gray-300 w-auto sm:h-auto p-1 flex flex-col rounded-t-xl rounded-b-md"
              href="/"
              onClick={()=>handleOnclick(item.prod_id)}
              key={index}
            >
              <div className="flex items-center justify-center border-b-2 border-gray-200">
                <img
                  src={item.prod_images[0]}
                  className="rounded-xl size-36 md:size-44"
                />
              </div>
              <span className="text-sm font-semibold capitalize !h-14 lg:!h-14">
                {item.prod_name}
              </span>
              <span className="font-thin text-sm">{item.weight}</span>
              <div>
                <span className="font-bold">{item.selling_price}</span>
                <span className="text-sm line-through">{item.mrp}</span>
              </div>
              <button className="border-1 h-10 font-semibold hover:bg-red-600 hover:text-white rounded-md border-red-600 text-red-600">
                Add to Cart
              </button>
            </div>
          );
        })} */
}
