import React from 'react'

function product() {
  return (
    <div className=''>
        <div className='border-1 h-10 '>

        </div>
    </div>
  )
}

export default product