import React, { useEffect, useRef } from "react";
import { useState } from "react";

function OtpInput({ length = 4, onOtpSubmit = () => {}, closeLoginModal }) {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const [isValidOtp, setIsValidOtp] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
//   const [apiRes, setApiRes] = useState();
  const inputRefs = useRef([]);
  //   console.log(inputRefs);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
    if(isLoading){
        setTimeout(() => {
            closeLoginModal();
        }, 1500);
        
    }
  }, [isLoading]);

  const handleOnsubmit = (e) => {
    // e.preventDefault();
    // do validation
    console.log(e);
    // closeLoginModal();
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];

    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      // do verification of otp from backend
      if (combinedOtp === "1234") {
        setIsValidOtp(true);
        setIsLoading(true);
        onOtpSubmit(combinedOtp);
        // closeLoginModal();
      } else {
        setIsLoading(false);
        setIsValidOtp(false);
      }
    }

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOnClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };


  return (
    <div>
      <form
        onSubmit={(e) => handleOnsubmit(e)}
        className="flex flex-row justify-center mt-2"
      >
        {otp.map((value, index) => {
          return (
            <input
              ref={(input) => (inputRefs.current[index] = input)}
              type="text"
              value={value}
              key={index}
              onChange={(e) => handleChange(index, e)}
              onClick={() => handleOnClick(index)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="border h-10 w-10 outline-lime-500  rounded p-1 m-1.5 text-center text-xl"
            />
          );
        })}
      </form>

      <div className="flex justify-center">
        {!isValidOtp ? <p className="text-red-600 ">OTP is incorrect !</p> : ""}
        {isLoading ? <p className="text-lime-600 ">OTP is correct !</p> : ""}
      </div>
    </div>
  );
}

export default OtpInput;
