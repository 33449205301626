import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useParams, useLocation } from "react-router-dom";
import Carousel from "./Carousel/Carousel";
import ProductTemplate from "../../components/Templates/ProductTemplate";

function ProductDetails({ data, setProductPage }) {
  // const [cartSize, setCartSize] = useState(0);

  setProductPage(true);

  const location = useLocation();
  const { category, id, name } = useParams();

  const [allProd, setAllProds] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id, setProductPage, location]);

  const [open, setOpen] = useState(false);

  const [prod_data, setProd_Data] = useState({
    category: "",
    prod_name: "",
    prod_id: "",
    prod_images: [],
    brand_name: "",
    mrp: "",
    selling_price: "",
    weight: "",
  });

  // category: "fruits & vegetables",
  //   prod_name: "Fresho Coriander Leaves",
  //   prod_id: "10000097",
  //   prod_images: [
  //     "https://www.bigbasket.com/media/uploads/p/l/10000097_19-fresho-coriander-leaves.jpg",
  //     "https://www.bigbasket.com/media/uploads/p/l/10000097-2_1-fresho-coriander-leaves.jpg",
  //     "https://www.bigbasket.com/media/uploads/p/l/10000097-3_2-fresho-coriander-leaves.jpg",
  //     "https://www.bigbasket.com/media/uploads/p/l/10000097-4_1-fresho-coriander-leaves.jpg",
  //     "https://www.bigbasket.com/media/uploads/p/l/10000097-5_1-fresho-coriander-leaves.jpg",
  //   ],
  //   brand_name: "",
  //   mrp: "₹13.7",
  //   selling_price: "₹9(₹0.09 / g)",
  //   weight: "100 g ",

  const prodArray = [];

  useEffect(() => {
    data.categories.length !== 0 &&
      data.categories.map((item) => {
        item.sub_categories.length !== 0 &&
          item.sub_categories.map((sub_item) => {
            sub_item.products.length !== 0 &&
              sub_item.products.map((prod_item) => {
                if (
                  item.cate_name.replace(/ /g, "").replaceAll(",", "-") ===
                  category
                ) {
                  prodArray.push({
                    category: item.cate_name,
                    prod_id: prod_item.prod_id,
                    prod_name: prod_item.prod_name,
                    prod_images: prod_item.prod_images,
                    mrp: prod_item.mrp,
                    selling_price: prod_item.selling_price,
                    weight: prod_item.weight,
                  });
                  // console.log(id);
                  if (prod_item.prod_id === id) {
                    setProd_Data({
                      category: item.cate_name,
                      prod_id: prod_item.prod_id,
                      prod_name: prod_item.prod_name,
                      prod_images: prod_item.prod_images,
                      mrp: prod_item.mrp,
                      selling_price: prod_item.selling_price,
                      weight: prod_item.weight,
                    });
                    // console.log(prod_item);
                  }
                }
              });
          });
      });
    setAllProds(prodArray);
    // console.log(prodArray);
  }, [data, id]);

  const handleButtonAddCart = (e) => {
    e.preventDefault();
    console.log(e);
  };

  return (
    <div className="productDetails sm:max-w-7xl sm:mx-auto overflow-y-hidden">
      <div className="flex flex-1 flex-col sm:gap-2 sm:mt-24 sm:flex-row xl:px-0">
        <p className="sm:hidden mx-3 font-normal text-sm">
          <span>
            <span>
              <a
                className="text-gray-400 hover:text-lime-600 no-underline"
                href="/"
              >
                Home
              </a>
            </span>
            &gt;
          </span>
          <span>
            <span>
              <a
                href="/"
                className="text-gray-400 hover:text-lime-600 capitalize no-underline"
              >
                {prod_data.category}
              </a>
            </span>
            &gt;
          </span>
          <span>{prod_data.prod_name}</span>
        </p>
        <div className="mx-4 p-2">
          {/* <Carousal images={prod_data.prod_images} /> */}
          <Carousel images={prod_data.prod_images} />
        </div>

        <hr className="mt-4" />
        <div className="sm:grow sm:border-t-0">
          <div className="flex flex-col gap-0 m-2">
            <p className="hidden sm:block font-normal text-sm">
              <span>
                <span>
                  <a
                    className="text-gray-400 hover:text-lime-600 no-underline"
                    href="/"
                  >
                    Home
                  </a>
                </span>
                &gt;
              </span>
              <span>
                <span>
                  <a
                    href="/"
                    className="text-gray-400 hover:text-lime-600 capitalize no-underline"
                  >
                    {prod_data.category}
                  </a>
                </span>
                &gt;
              </span>
              <span>{prod_data.prod_name}</span>
            </p>
            <div className="text-xl sm:text-xl font-bold">
              {prod_data.prod_name}
            </div>
            <div>{prod_data.weight}</div>
            <div className="flex">
              <div className="font-bold text-lg">{prod_data.selling_price}</div>
              <div className="text-base line-through">{prod_data.mrp}</div>
            </div>
            <div className="justify-between items-center">
              <button
                onClick={() => handleButtonAddCart()}
                className="sm:w-28 bg-red-600 text-white h-10 w-full font-semibold  rounded-md border-red-600"
              >
                Add to Cart
              </button>
            </div>
            {/* <hr className="max-w-xl m-2 mt-4" /> */}
            <div className="flex flex-col border-t max-w-xl m-2">
              <div className="flex justify-between">
                <span className="font-bold">Product Details</span>
                <ChevronDown
                  onClick={() => setOpen(true)}
                  className={open ? "hidden" : "cursor-pointer stroke-1"}
                />
                <ChevronUp
                  onClick={() => setOpen(false)}
                  className={open ? "cursor-pointer stroke-1" : "hidden"}
                />
              </div>
              <ul className={open ? "text-sm font-light list-disc" : "hidden"}>
                <li>
                  Description : Ghee is a class of clarified butter that
                  originated in ancient India. It is commonly used in Indian
                  cooking. Gowardhan Pure Cow Ghee can be swapped for vegetable
                  oil or coconut oil in baked goods or can be used for
                  deep-frying. Or simply melt it and spread it on roti, or pour
                  it on vegetables or dal before serving.
                </li>
                <li>Country of Origin : India</li>
                <li>Shelf Life : 12 months</li>
                <li>Manufacturer Name : Parag Milk Foods Ltd</li>
                <li>
                  Manufacturer Address : Parag Milk Foods Ltd, 20th Flr, Nirmal
                  Building, Nariman Point, Mumbai 400021.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex mt-4">
          <span className="sm:text-xl font-bold m-1">Similar Products</span>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-2 m-2">
          {allProd.length !== 0 &&
            allProd.map((item, index) => {
              return (
                <ProductTemplate
                  data={item}
                  setProductPage={setProductPage}
                  key={index}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;

// <div className="sm:mx-auto sm:max-w-7xl">
//   <div className="flex flex-1 flex-col sm:mt-28 sm:flex-row xl:px-0">
//     <div className="h-full sm:shrink-[1] sm:grow-[5] sm:basis-0 sm:px-4">
//       <div className="mb-6 w-screen sm:mx-auto sm:w-full sm:rounded-md sm:border sm:border-skin-primary-void/10"></div>
//       <div className="relative overflow-hidden">
//         <div className="relative flex h-full">
//           <div className=" hidden duration-700 ease-in-out border-">
//             <img
//               alt="Product image"
//               loading="lazy"
//               decoding="async"
//               // data-nimg="1"
//               className="!absolute block size-auto min-w-full aspect-square undefined"
//               src="https://www.bigbasket.com/media/uploads/p/l/10000097_19-fresho-coriander-leaves.jpg"
//             />
//           </div>
//           <div className="hidden duration-700 ease-in-out">
//             <img
//               alt="Product image"
//               loading="lazy"
//               decoding="async"
//               data-nimg="1"
//               className="size-auto !relative min-w-full aspect-square undefined bg-transparent object-contain text-transparent"
//               src="https://www.bigbasket.com/media/uploads/p/l/10000097-2_1-fresho-coriander-leaves.jpg"
//             />
//           </div>
//           <div className="hidden duration-700 ease-in-out">
//             <img
//               alt="Product image"
//               loading="lazy"
//               decoding="async"
//               data-nimg="1"
//               className="size-auto !relative min-w-full aspect-square undefined"
//               src="https://www.bigbasket.com/media/uploads/p/l/10000097-3_2-fresho-coriander-leaves.jpg"
//             />
//           </div>
//           <div className="hidden sm:block">
//             <button
//               className="py-1 text-base border-skin-primary border-none text-opacity-100 absolute inset-y-0 left-0 !px-0 false"
//               type="button"
//             >
//               <div className="flex items-center justify-center">
//                 <div className="bg-skin-base mx-2 flex h-8 w-8 items-center justify-center rounded-full shadow-lg">
//                   <ChevronLeft />
//                 </div>
//               </div>
//             </button>
//             <button className="absolute inset-y-0 right-0 !px-0">
//               <div className="bg-skin-base mx-2 flex h-8 w-8 items-center justify-center rounded-full shadow-lg">
//                 <ChevronRight />
//               </div>
//             </button>
//             {/* <button
//               className=" py-1 px-7 text-base button_btn-text__19vrm undefined border-skin-primary border absolute inset-y-0 left-0 !px-0 false "
//               type="button"
//             >
//               <div className="flex items-center justify-center">
//                 <div className="bg-skin-base mx-2 flex h-8 w-8 items-center justify-center rounded-full shadow-lg">
//                   <svg
//                     fill="none"
//                     height="12"
//                     viewBox="0 0 10 18"
//                     width="12"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M8.5 16L1.5 9L8.5 2"
//                       stroke="black"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                       stroke-width="2.5"
//                     ></path>
//                   </svg>
//                 </div>
//               </div>
//             </button>
//             <button
//               aria-label="right arrow"
//               class="absolute inset-y-0 right-0 !px-0"
//             >
//               <div className="bg-skin-base mx-2 flex h-8 w-8 items-center justify-center rounded-full shadow-lg">
//                 <svg
//                   fill="none"
//                   height="12"
//                   viewBox="0 0 6 10"
//                   width="12"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <line
//                     stroke="black"
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     stroke-width="1.5"
//                     x1="1"
//                     x2="4.93934"
//                     y1="8.93934"
//                     y2="5"
//                   ></line>
//                   <line
//                     stroke="black"
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     stroke-width="1.5"
//                     x1="4.93934"
//                     x2="1"
//                     y1="5"
//                     y2="1.06066"
//                   ></line>
//                 </svg>
//               </div>
//             </button> */}
//           </div>
//         </div>
//         <div></div>
//         <div></div>
//       </div>
//     </div>
//     <hr />
//     <div className=" flex flex-col px-4 sm: sticky sm:shrink-DEFAULT sm:grow-[5] sm:basis-0 sm:self-start sm:pl-10">
//       Product details
//     </div>
//   </div>
// </div>
