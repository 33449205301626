import React, { useState } from 'react'

const getLocationName = async(lat, long)=> {
    // const [value, setValue] = useState();
    if(lat && long){
      const result = await getName(lat, long);
        return result.display_name;
    }

    async function getName(lat, long) {
        const promise = await fetch(
          `${process.env.REACT_APP_LOCATION_NAME}reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`
        );
        return await promise.json();
      }
  return null;
}

export default getLocationName;